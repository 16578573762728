import React, { useState, useEffect, useRef } from "react"
import QRCodeStyling from "qr-code-styling"
import copy from "copy-to-clipboard"
import ImageSVG from "../../abis/ImageSVG.json"

import { PiCopyBold } from "react-icons/pi"

const ModalShare = (props) => {
  const closeModal = async () => {props.closeModal(true)}

  const [refcode, setRefcode] = useState("")
  const [link, setLink] = useState("")
  const [message, setMessage] = useState(false)

  useEffect(() => {
    if(props.message){
      const array = (props.message.toString()).split("refcode:")
      setLink(array[0])
      setRefcode(array[1])
      qrCode.update({data: array[0]})
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const qrCode = new QRCodeStyling({
    width: 200, height: 200, type: "svg",
    image: ImageSVG.greenlogo,
    margin:10,
    dotsOptions: {
      color: "#4267b2",
      type: "rounded",
    },
    imageOptions: {crossOrigin: "anonymous", margin: 10, imageSize: 0.5,}
  });
  const ref = useRef(null)
  useEffect(() => {qrCode.append(ref.current)}, [])// eslint-disable-line react-hooks/exhaustive-deps

  function copyText(text) {
    copy(text); setMessage(true)
    const interval = setInterval(() => {setMessage(false)}, 500)
    return () => clearTimeout(interval)
  }

  return (
    <div className="modal2">

        <div id="message-box">
          {message && <div className="copy-msg"><PiCopyBold/></div>}
          <div className="header">
            <div className="face4">
              <div className="icon"><img src={require("../images/logo.png")} alt=""/></div>
            </div>
          </div>


          <div className="message">
            <h1 className="normal">{refcode} <i className="smallicon" onClick={()=>copyText(`${refcode}`)}><PiCopyBold/></i></h1>
            <div className="border box-share">
              {link} <i className="smallicon" onClick={()=>copyText(`${link}`)}><PiCopyBold/></i>
              <div className="qrcore" ref={ref}/>
            </div>
          </div>
          <p className="button-box" onClick={()=>closeModal()}><span className="green">continue</span></p>
        </div>

    </div>
  )
}

export default ModalShare