import React, { useState } from "react"
import { fallDown as Menu } from "react-burger-menu"
import APK from "./images/grcoiner.apk"
import { FiAlertTriangle } from "react-icons/fi"
const Sidebar = (props) => {
    const [userInfo, setUserInfo] = useState(localStorage.getItem("greennetwork"))
    function LogOut() {
      localStorage.removeItem("greennetwork")
      localStorage.removeItem("airdrop")
      setUserInfo(null)
      props.logOut(true)
    }

    const [isOpen, setIsOpen] = useState(false)
    const Open = function (){setIsOpen(true)}

    const [message, setMessage] = useState("")
    const Alert = async (message) => {
        setMessage(message)
        setTimeout(() => {setMessage("")}, 1000)
    }
    
    return (
        <div className="sidebar">
            
            <Menu left width="100%" isOpen={isOpen}
                customBurgerIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16"><path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/></svg> }
                htmlClassName="htmlClassName"
                onOpen={Open}
                >
                <button className="bm-cross-button" onClick={()=>setIsOpen(false)}><i className="bi bi-chevron-double-up"></i></button>

                {message && <div className="sidebar-message"><span>{message}</span></div>}

                <a className="menu-item" href="/">Home</a>
                
                <a className="menu-item">Token</a>
                <div className="menu-item-dropdown">
                    <a className="menu-item" href="/contract">Contract</a>
                    <a className="menu-item" href="/stake">Staking</a>
                    <a className="menu-item" href="/">Buyback</a>
                </div>

                <a className="menu-item">Account</a>
                <div className="menu-item-dropdown">
                    {userInfo?
                    <>
                        <a className="menu-item" href="/airdrop">Airdrop</a>
                        <a className="menu-item" href="/dashboard">Dashboard</a>
                        <a className="menu-item" href="/security">Security</a>
                        <a className="menu-item" href="/referral">Referral</a>
                        <span style={{position:"relative"}}><a className="menu-item" onClick={()=>Alert("MAINTENANCE")}>KYC</a><FiAlertTriangle style={{position:"absolute", top:"5px", right:"0", color:"#D1D5DB"}}/></span>
                        <a className="menu-item" href="/logs">My Logs</a>
                        <a className="menu-item" href="/delete">Account deletion</a>
                        <a className="menu-item signout" onClick={()=>{LogOut();setIsOpen(false)}}>Sign Out</a>
                    </>:
                    <>
                        <a className="menu-item" href="/login">Login</a>
                        <a className="menu-item" href="/register">Register</a>
                        <a className="menu-item" href="/login">Forgot password?</a>
                    </>
                    }
                </div>

                <a className="menu-item">About</a>
                <div className="menu-item-dropdown">
                    <a className="menu-item" onClick={()=>setIsOpen(false)} href="/#roadmap">Roadmap</a>
                    <a className="menu-item" href="/whitepaper">Whitepaper</a>
                    <a className="menu-item" onClick={()=>Alert("COMING SOON")}>Document</a>
                </div>
                
                <a className="menu-item">Download</a>
                <div className="menu-item-dropdown">
                    <a className="menu-item" onClick={()=>Alert("COMING SOON")}>Google Play</a>
                    <a className="menu-item" onClick={()=>Alert("COMING SOON")}>App Store</a>
                    <a className="menu-item" download href={APK}>Android APK</a>
                </div>

                <a className="menu-item" onClick={()=>setIsOpen(false)}>
                    Language <img src={require("./images/flag.png")} alt=""/> <i className="bi bi-chevron-down"></i>
                </a>

                <br/><br/><br/><br/>

            </Menu>

        </div>
    )
}


export default Sidebar
