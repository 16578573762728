import React, { useState, useEffect } from "react"
import ImageSVG from "../abis/ImageSVG.json"

import Web3 from "web3"
import Network from "../abis/Network.json"
import BuyBackABI from "../abis/BuyBackABI.json"
import TokenABI from "../abis/TokenABI.json"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"

import { PiDotsThreeOutlineLight } from "react-icons/pi"

const BuyBack = (props) => {
  const closeModal = async () => {props.closeModal(true)}
  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState('')
  const [message2, setMessage2] = useState('')
  const [message3, setMessage3] = useState('')
  const [message4, setMessage4] = useState('')

  const [amount, setAmount] = useState(0)
  const [tokenbalance, setTokenbalance] = useState(0)
  const [account, setAccount] = useState('')
  const [nativeTokenPrice, setNativeTokenPrice] = useState(0)

  const onBlockchain = async () => {  
    const web3 = new Web3(Network.rpcUrls)
    const Token = new web3.eth.Contract(BuyBackABI.abi, Network.BuyBackAddress)
    setNativeTokenPrice(100000000/Number(await Token.methods.nativeTokenPrice().call()))
  }

  useEffect(() => {
    onBlockchain()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const Connect = async () => {  
    setLoader(true)
    try{
      if(window.ethereum){
          window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
          //Check Network
          if(await web3.eth.net.getId() !== Network.chainId) {
              try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
              }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
          }//Check Network
          const accounts = await web3.eth.getAccounts(); setAccount(accounts[0]);
          const Token = new web3.eth.Contract(TokenABI.abi, Network.Token)
          setTokenbalance(Number(web3.utils.fromWei(await Token.methods.balanceOf(accounts[0]).call())))
      }
    }catch(error){}
    setLoader(false)
  }

  const Buyback = async () => { 
    if(!account){return setMessage2("Wallet not connected")} 
    if(!amount || amount <1000){return setMessage2("Min: 1000 GRC")} 
    if(amount>tokenbalance){return setMessage2("Insufficient GRC balance")} 
    setLoader(true)
    try{
      if(window.ethereum){
          window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
          //Check Network
          if(await web3.eth.net.getId() !== Network.chainId) {
              try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
              }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
          }//Check Network
          const accounts = await web3.eth.getAccounts(); setAccount(accounts[0]);
          const Token = new web3.eth.Contract(BuyBackABI.abi, Network.BuyBackAddress)

          let fee
          if(amount <= 5000){fee = (nativeTokenPrice*1.1).toFixed(15)}
          else {fee = (amount/5000 * nativeTokenPrice * 1.1).toFixed(15)}

          const value = web3.utils.toWei(fee.toString(), "ether")

          await Token.methods.buyback(amount).send({from: accounts[0], value, maxPriorityFeePerGas: null, maxFeePerGas: null})
          .then((receipt) => {setMessage2("Failed. Please try again.")})
      }
    }catch(error){console.log(error)}
    setLoader(false)
  }

  const addToken = async () => { 
    if(window.ethereum){
      window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3
      //Check Network
      if(await web3.eth.net.getId() !== Network.chainId) {
      try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
      }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
      }//Check Network

      await window.ethereum.request({method: 'wallet_watchAsset',params: {
          type: 'ERC20', options: {address: "0x55d398326f99059fF775485246999027B3197955", symbol:"USDT", decimals: 18, image: "https://bscscan.com/token/images/busdt_32.png"}
      }})
    }
  }

  return (
    <div className="confirm-modal unselectable">
      {loader && <Loader/>}
      {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
      {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
      {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
      {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}

        <article className="modal-container">
          <header className="modal-container-header">
            <h2 className="modal-container-title">
              <img src={ImageSVG.greenlogo} alt=""/>
              Buy Back
            </h2>
            <button className="icon-button" onClick={()=>closeModal()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
              </svg>
            </button>
          </header>

          <section className="modal-container-body rtf">
            <div className="buyback">
              <h3>FIRST COME, FIRST SERVE</h3>
              <p>End:  25/09/2024 09:00 AM</p>
              <p>Total: 100M GRC token</p>
              <p>Price: $0.0025 per 1 GRC</p>
              <p>Connected: {account ? 
                <>{account.slice(0, 7)}<PiDotsThreeOutlineLight/>{account.slice(account.length-5, account.length)}</>:
                <button onClick={()=>Connect()}>Connect wallet</button>}
              </p>
              <p>GRC Balance: {tokenbalance>0 && Math.round(tokenbalance*1000000)/1000000}</p>
              <form id="buyback" onSubmit={(event)=>{event.preventDefault(); Buyback()}}>
              <input type="number" placeholder="Amount of GRC" value={amount>0 && amount} min={1000}
                onChange={(e)=>setAmount(e.target.value)}/>
              </form>
              <p>Receive: <strong>{amount >0 && Math.round(amount*0.0025 *1000000)/1000000}</strong></p>
              <p>Binance-Peg BSC-USD (BSC-USD) <span style={{cursor:"pointer"}} onClick={()=>addToken()}>+Add</span></p>
              
            </div>
            
          </section>
          <footer className="modal-container-footer">
            <button className="button is-primary" form="buyback">SUBMIT</button>
          </footer>
        </article>

    </div>
  )
}

export default BuyBack