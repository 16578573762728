import React, { useState, useEffect } from "react"
const Modal2 = (props) => {
  const closeModal = async () => {props.closeModal(true)}

  const [text, setText] = useState([])

  useEffect(() => {
    if(props.message){setText(props.message)}
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal1 unselectable">
        <div id="error-box">
          <div className="dot"></div><div className="dot two"></div><div className="dot three"></div>
          <div className="header">
            <div className="face2">
              <div className="eye error-eye"></div>
              <div className="eye error-eye right"></div>
              <div className="mouth sad"></div>
            </div>
            <div className="shadow move"></div>
          </div>

          <div className="message">
            <h1 className="alert">FAILURE</h1>
            <p>{text}</p>
          </div>
          <p className="button-box" onClick={()=>closeModal()}><span className="red">TRY AGAIN</span></p>
        </div>
    </div>
  )
}

export default Modal2