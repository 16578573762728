import React from "react"
import ImageSVG from "../../abis/ImageSVG.json"

const Selfie = (props) => {

  const closeModal = async () => {props.closeModal(true)}

  return (
    <div className="confirm-modal unselectable">

        <article class="modal-container">
          <header class="modal-container-header">
            <h2 class="modal-container-title">
              <img src={ImageSVG.greenlogo} alt=""/>
              Take a selfie
            </h2>
            <button class="icon-button" onClick={()=>closeModal()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
              </svg>
            </button>
          </header>

          <section class="modal-container-body rtf">


            <p>1.	Select a location that has good lighting</p>
            <p>2.	Hold your ID document and a <b>handwritten note</b> next to your face</p>
            <p style={{color:"#EF4444"}}>The handwritten note must say "Green Network" and the current date. Don't type the note, write the note with your hand.  Use a white paper that is about 1/4th the size of a A4 sheet</p>
            <p>3.	Check that your face, the ID and the handwritten note can be seen clearly and are not covered</p>
            <p>4.	Relax and take the photo</p>
            <p>5.	Check the photo to make sure that your face, the ID and the handwritten note are clear and in focus</p>
            

          </section>
          <footer class="modal-container-footer">
            <button class="button is-primary" onClick={()=>closeModal()}>Understood</button>
          </footer>
        </article>

    </div>
  )
}

export default Selfie