import React, { useState, useEffect } from "react"
import Web3 from "web3"
import Network from "../abis/Network.json"
import TokenABI from "../abis/TokenABI.json"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"

import { IoMdCloseCircle } from "react-icons/io"
import { FaCloudDownloadAlt } from "react-icons/fa"
import { IoReloadCircleOutline } from "react-icons/io5"
import { PiDotsThreeOutlineLight } from "react-icons/pi"

const Stake = () => {
  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState(''); const [message2, setMessage2] = useState('')
  const [message3, setMessage3] = useState(''); const [message4, setMessage4] = useState('')
  const userInfo = localStorage.getItem("greennetwork")

  const [disaple, setDisaple] = useState(false)
  const [account, setAccount] = useState('')

  const [showPlan, setShowPlan] = useState(false)
  const [price, setTokenprice] = useState(0)
  const [apr1w, setAPR1W] = useState(0); const [apr2w, setAPR2W] = useState(0); const [apr3w, setAPR3W] = useState(0);
  const [apr1m, setAPR1M] = useState(0); const [apr2m, setAPR2M] = useState(0); const [apr3m, setAPR3M] = useState(0); const [apr4m, setAPR4M] = useState(0);
  const [apr5m, setAPR5M] = useState(0); const [apr6m, setAPR6M] = useState(0); const [apr7m, setAPR7M] = useState(0); const [apr8m, setAPR8M] = useState(0);
  const [apr9m, setAPR9M] = useState(0); const [apr10m, setAPR10M] = useState(0); const [apr11m, setAPR11M] = useState(0);
  const [apr1y, setAPR1Y] = useState(0); const [apr0d, setAPR0D] = useState(0);
  const [showDeposit, setShowDeposit] = useState(false)
  const [plan, setPlan] = useState(0)
  const [amount, setAmount] = useState(0)

  const onBlockchain = async () => {  
    const web3 = new Web3(Network.rpcUrls)
    const Token = new web3.eth.Contract(TokenABI.abi, Network.Token)
    setTokenprice(await Token.methods.tokenPrice().call())
    setAPR0D(await Token.methods.APR(0).call())
    setAPR1W(await Token.methods.APR(7).call())
    setAPR2W(await Token.methods.APR(14).call())
    setAPR3W(await Token.methods.APR(21).call())
    setAPR1M(await Token.methods.APR(30).call())
    setAPR2M(await Token.methods.APR(60).call())
    setAPR3M(await Token.methods.APR(90).call())
    setAPR4M(await Token.methods.APR(120).call())
    setAPR5M(await Token.methods.APR(150).call())
    setAPR6M(await Token.methods.APR(180).call())
    setAPR7M(await Token.methods.APR(210).call())
    setAPR8M(await Token.methods.APR(240).call())
    setAPR9M(await Token.methods.APR(270).call())
    setAPR10M(await Token.methods.APR(300).call())
    setAPR11M(await Token.methods.APR(330).call())
    setAPR1Y(await Token.methods.APR(365).call())
  }
  useEffect(() => {onBlockchain()}, [])

  const trustwallet_deeplink = "https://link.trustwallet.com/open_url?coin_id=20000714&url=https://grcoiner.io/stake"
  const [selectWallet, setSelectWallet] = useState(false)
  const [deeplinkQR, setDeeplinkQR] = useState(false)

  const [mystaked, setMyStaked]=useState([])
  const [claimable, setMyClaimable]=useState([])
  const [myendtime, setMyEndtime]=useState([])
  const [myoption, setMyOption]=useState([])

  const Round = function(number){return Math.floor(number/1000000000000)/1000000}
  const UnRound = function(number){return number/1000000000000000000}

  useEffect(() => {
    const interval = setInterval(() => {
      if(account && mystaked.length>0 && !loader){Profit(account)}
    }, 5000)
    return () => clearTimeout(interval)
  })

  const [myprofit, setMyProfit]=useState([])
  const Profit = async (account) => { 
    const web3 = new Web3(Network.rpcUrls)
    const Token = new web3.eth.Contract(TokenABI.abi, Network.Token)
    var _profit = []
    for(var i=0; i<mystaked.length; i++){
      const profit = UnRound(Number(await Token.methods.myProfit(account, i).call())).toFixed(6)
      _profit.push(profit)
    }
    setMyProfit(_profit)
  }

  const Connect = async () => {  
    setLoader(true)
    setDisaple(true)
    try{
      if(window.ethereum){
          window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
          //Check Network
          if(await web3.eth.net.getId() !== Network.chainId) {
              try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
              }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
          }//Check Network
          const accounts = await web3.eth.getAccounts(); setAccount(accounts[0])
          const Token = new web3.eth.Contract(TokenABI.abi, Network.Token)

          const mystaked = await Token.methods.myStaked(accounts[0]).call()
          const myoption = await Token.methods.myOption(accounts[0]).call()
          const mycreated = await Token.methods.myCreated(accounts[0]).call()

          if(mycreated.length>0){
            var today = new Date()
            var newEndtime = []; var availble = []; var _profit = [];
            for(var i=0; i<mycreated.length; i++){              
              const daySet = new Date(Number(mycreated[i])*1000)
              const newDate = new Date(new Date(daySet).setDate(Number(daySet.getDate())+Number(myoption[i])))
              const newDateString = 
                ("0" + newDate.getDate()).slice(-2) + "/" +
                ("0" + (newDate.getMonth()+1)).slice(-2) + "/" +
                newDate.getFullYear() + " " +
                ("0" + newDate.getHours()).slice(-2) + ":" +
                ("0" + newDate.getMinutes()).slice(-2)
              newEndtime.push(newDateString)
              if(today-newDate>=0){availble.push("true")} else{availble.push("false")}
              const profit = UnRound(Number(await Token.methods.myProfit(accounts[0], i).call())).toFixed(6)
              _profit.push(profit)
            }
          }

          await setMyStaked(mystaked)
          await setMyOption(myoption)
          await setMyEndtime(newEndtime)
          await setMyClaimable(availble)
          await setMyProfit(_profit)

          setDisaple(false)
      } else {
          setSelectWallet(true)
      }
    }catch(error){console.log(error)}
    setLoader(false)
  }

  const stakeToken = async (e) => {  
    e.preventDefault()
    setLoader(true)
    setShowDeposit(false); setShowPlan(false)
    try{
      if(window.ethereum){
          window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
          //Check Network
          if(await web3.eth.net.getId() !== Network.chainId) {
              try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
              }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
          }//Check Network
          const accounts = await web3.eth.getAccounts(); setAccount(accounts[0]);
          const Token = new web3.eth.Contract(TokenABI.abi, Network.Token)
          const balance = await Token.methods.balanceOf(accounts[0]).call()
          const value = web3.utils.toWei(amount.toString(), "ether")
          if(Number(value)>Number(balance)){
            setMessage2("Insufficient balance")
            setLoader(false)
            setShowDeposit(true)
          }else{
            await Token.methods.stake(plan, value).send({from: accounts[0], maxPriorityFeePerGas: null, maxFeePerGas: null})
            .then((receipt) => {return Connect()})
          }
      } else {
        setSelectWallet(true)
      }
    }catch(error){setShowDeposit(true)}
    setLoader(false)
  }

  const unstakeToken = async (index) => {  
    setLoader(true)
    try{
      if(window.ethereum){
        window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
        //Check Network
        if(await web3.eth.net.getId() !== Network.chainId) {
            try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
            }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
        }//Check Network
        const accounts = await web3.eth.getAccounts(); setAccount(accounts[0]);
        const Token = new web3.eth.Contract(TokenABI.abi, Network.Token)
        await Token.methods.unstake(index).send({from: accounts[0], maxPriorityFeePerGas: null, maxFeePerGas: null})
        .then((receipt) => {Connect()})
      } else {setSelectWallet(true)}
    }catch(error){console.log(error)}
    setLoader(false)
  }

  const claimProfit = async (index) => {  
    setLoader(true)
    try{
      if(window.ethereum){
        window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
        //Check Network
        if(await web3.eth.net.getId() !== Network.chainId) {
            try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
            }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
        }//Check Network
        const accounts = await web3.eth.getAccounts(); setAccount(accounts[0]);
        const Token = new web3.eth.Contract(TokenABI.abi, Network.Token)
        await Token.methods.profit(index).send({from: accounts[0], maxPriorityFeePerGas: null, maxFeePerGas: null})
        .then((receipt) => {Connect()})
      } else {setSelectWallet(true)}
    }catch(error){console.log(error)}
    setLoader(false)
  }

return (
    <main>
      <Header>{userInfo ? localStorage.getItem("airdrop"):0}</Header><Sidebar/>
      {loader && <Loader/>}
      {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
      {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
      {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
      {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}

      <div className="main-section unselectable">
        <div className="dashboard" style={{width: "800px"}}>
          <span className="information d-flex align-items-center">
            <img src={require("./images/squarelogo64.png")} alt=""/>
            <span className="text"><big>SELECT PLAN <button className="select-plan" onClick={()=>{setShowPlan(true); setDisaple(true)}}><FaCloudDownloadAlt/></button></big></span>
          </span>

          <div className="presale-details" style={{marginTop:"5px"}}>
            <p style={{position:"relative"}}>
              {account?
                <>
                  <i>Connected: {account && `${account.slice(0, 7)}`}{account && <PiDotsThreeOutlineLight/>}{account && `${account.slice(account.length-5, account.length)}`}</i>
                  &nbsp;<span className="reconnect" onClick={()=>Connect()}><IoReloadCircleOutline/></span>
                </>:
                <button disabled={disaple} className="normal-btn" onClick={()=>Connect()}>Connect Wallet</button>
              }
              {selectWallet && 
              <i className="selectWallet">
                <a><img onClick={()=>{setSelectWallet(false); setDeeplinkQR(true)}} src={require("./images/metamask.png")} alt=""/></a>
                <a href={trustwallet_deeplink} target="_blank"><img onClick={()=>{setSelectWallet(false); setDisaple(false)}} src={require("./images/trustwallet.png")} alt=""/></a>
                <i className="close" onClick={()=>{setSelectWallet(false); setDisaple(false)}}><IoMdCloseCircle/></i>
              </i>}
              {deeplinkQR && 
              <i className="selectWallet-QR" style={{paddingTop:"5px"}}>
                <span>Open Metamask & Scan QR code</span>
                <i className="qrcode" onClick={()=>{setSelectWallet(false)}}>
                  <img src={require("./images/metamask-deeplink-stake.png")} alt=""/>
                </i>
                <div className="close" style={{top:"3px"}} onClick={()=>{setDeeplinkQR(false); setDisaple(false)}}><IoMdCloseCircle/></div>
              </i>}
            </p>
          </div>
          
          {showPlan || showDeposit ? 
            <div className="stake-plan">            
              <div className="header">
                <div className="text">STAKING PLANS</div>
                <div className="close" onClick={()=>{setShowPlan(false); setShowDeposit(false); setDisaple(false); setAmount(0)}}><IoMdCloseCircle/></div>
              </div>

              {showPlan && <div className="body">
                <div className="plan">
                  <div className="name"><p>♾️</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: Flexible</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr0d}%</p>
                  </div>
                  <button onClick={()=>{setPlan(0); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>1W</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 07d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr1w}%</p>
                  </div>
                  <button onClick={()=>{setPlan(7); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>2W</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 14d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr2w}%</p>
                  </div>
                  <button onClick={()=>{setPlan(14); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>3W</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 21d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr3w}%</p>
                  </div>
                  <button onClick={()=>{setPlan(21); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>1M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 30d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr1m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(30); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>2M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 60d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr2m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(60); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>3M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 90d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr3m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(90); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>4M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 120d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr4m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(120); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>5M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 150d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr5m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(150); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>6M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 180d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr6m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(180); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>7M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 210d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr7m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(210); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>8M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 240d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr8m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(240); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>9M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 270d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr9m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(270); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>10M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 300d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr10m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(300); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>11M</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 330d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr11m}%</p>
                  </div>
                  <button onClick={()=>{setPlan(300); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
                <div className="plan">
                  <div className="name"><p>1Y</p></div>
                  <div className="info">
                    <p><img src={require("./images/check.png")} alt=""/> Lock-in period: 365d</p>
                    <p><img src={require("./images/check.png")} alt=""/> APR: {apr1y}%</p>
                  </div>
                  <button onClick={()=>{setPlan(365); setShowPlan(false); setShowDeposit(true)}}>Select</button>
                </div>
              </div>}
              {showDeposit && <div className="stake-deposit">
                <h1>{plan===0?<i className="bi bi-infinity"></i>:
                  plan===7?"1W":plan===14?"2W":plan===21?"3W":plan===30?"1M":plan===60?"2M":plan===90?"3M":plan===120?"4M":plan===150?"5M":plan===180?"6M":
                  plan===210?"7M":plan===240?"8M":plan===270?"9M":plan===300?"10M":plan===330?"11M":plan===365?"1Y": "N/A"
                }</h1>

                <h4>AMOUNT OF GRC</h4>
                <form id="deposit" onSubmit={stakeToken}>
                  <input required type="number" value={amount>0?amount:""} onChange={(e)=>setAmount(e.target.value)}/>
                </form>
                
                <button onClick={()=>{setShowDeposit(false); setShowPlan(true); setPlan(0); setAmount(0)}}>BACK</button><button form="deposit">DEPOSIT</button>
              </div>}

              <div className="footer">
              </div>
            </div>:<></>
          }
          
          <div className="staked-details-header"><h4>MY STAKE</h4></div>
          <div className="staked-details">
            
            {mystaked && mystaked[0] && myoption && myoption[0] && myendtime && myendtime[0] && claimable && claimable[0] && myprofit && myprofit[0] && mystaked.map((mystaked, i) => (
              <div className="mystake" key={i}>
                <div className="mystake-image">
                  <div className="plan-name">
                    {myoption[i]==="0"?<i className="bi bi-infinity"></i>:
                      myoption[i]==="7"?"1W":myoption[i]==="14"?"2W":myoption[i]==="21"?"3W":myoption[i]==="30"?"1M":myoption[i]==="60"?"2M":myoption[i]==="90"?"3M":myoption[i]==="120"?"4M":myoption[i]==="150"?"5M":myoption[i]==="180"?"6M":
                      myoption[i]==="210"?"7M":myoption[i]==="240"?"8M":myoption[i]==="270"?"9M":myoption[i]==="300"?"10M":myoption[i]==="330"?"11M":myoption[i]==="365"?"1Y": "N/A"
                    }
                  </div>
                  {<button className="unstake-btn" disabled={claimable[i]==="true"?false:true} onClick={()=>unstakeToken(i)}>UNSTAKE</button>}
                </div>
                <div className="mystake-info">
                  <p>Staked: {Round(mystaked).toLocaleString("EN-us")}</p>
                  {myoption[i]==="0"?<p>No freezing</p>:<p>Endtime: {myendtime[i]}</p>}
                  <p style={{paddingBottom:"0", marginBottom:"0"}}>
                    Profit: {myprofit[i].toLocaleString("EN-us")}
                  </p>
                </div>
                {<button className="claim-btn" onClick={()=>claimProfit(i)}>Claim Profit</button>}
              </div>
            ))}
          </div>

        </div>

        <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
        </svg>

      </div>

    </main>

)
}


export default Stake
