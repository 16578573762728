import React, { useState, useEffect } from "react"
import { PiDotDuotone } from "react-icons/pi"
import ImageSVG from "../../abis/ImageSVG.json"

const Modal4 = (props) => {
  const closeModal = async () => {props.closeModal(true)}

  const [text, setText] = useState([])

  useEffect(() => {
    if(props.message){setText((props.message.toString()).split("/") )}
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal2 unselectable">

        <div id="message-box">
          <div className="header">
            <div className="face4">
              <div className="icon"><img src={ImageSVG.logo} alt=""/></div>
            </div>
          </div>

          <div className="message" style={{marginTop:"50px"}}>
            <div className="border">
              {text && text.map((i) => (<><i><PiDotDuotone/></i>{i}<br/></>))}
            </div>
          </div>
          <p className="button-box" onClick={()=>closeModal()}><span className="green">continue</span></p>
        </div>

    </div>
  )
}

export default Modal4