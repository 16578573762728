import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import $ from 'jquery'
import copy from "copy-to-clipboard"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"

import { FaArrowRotateRight, FaArrowRotateLeft  } from "react-icons/fa6"
import { TbFlipVertical, TbFlipHorizontal, TbHexagonNumber3  } from "react-icons/tb"
import { ImHome } from "react-icons/im"
import { GrView } from "react-icons/gr"

const Verify = () => {
  const userInfo = localStorage.getItem("greennetwork")
  const config = {headers: {"Content-Type": "application/json", Authorization: `Bearer ${userInfo && userInfo}`}}
  useEffect(() => {if (userInfo){getUser()}}, [])// eslint-disable-line react-hooks/exhaustive-deps

  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState(''); const [message2, setMessage2] = useState('')
  const [message3, setMessage3] = useState(''); const [message4, setMessage4] = useState('')
  const [userData, setUserdata] = useState()
  const [idnumber, setIDNumber] = useState('')

  const [lockbutton, setLockbutton] = useState(false)
  const [kycmessage, setKYCMessage] = useState([])
  const [totalrequest, setTotalRequest] = useState(0)

  const [history, setHistory] = useState(false)
  const [history_email, setHistory_Email] = useState("")
  const [history_idnumber, setHistory_ID] = useState("")
  const [history_url, setHistory_URL] = useState("")

  const getUser = async (user) => { 
    setLoader(true)
    try {
      const { data } = await axios.post(`/api/users/submitkyclist`, user, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        setUserdata(data.user)
        setTotalRequest(data.server.currentsubmitkyc)
        setIDNumber(data.user.idnumber)
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }
  const AddKYCMessage = async(text) => {
    if(kycmessage.includes(text)){
      const index = kycmessage.indexOf(text)
      kycmessage.splice(index, 1)
      setKYCMessage(kycmessage)
    }else{
      kycmessage.push(text)
      setKYCMessage(kycmessage)
    }
  }
  const ref_acceptbtn = useRef(null)

  useEffect(() => {ref_acceptbtn.current.focus()}, [])

  const Accept = async (user) => {
    if(!lockbutton){
      setLoader(true)
      setLockbutton(true)
      setHistory_Email(userData.email); setHistory_URL(userData.pic1[0] && userData.pic1[0].url?userData.pic1[0].url:""); setHistory_ID(userData.idnumber)
      try {
        const userid = userData._id
        const { data } = await axios.post(`/api/users/accept`, {userid, user}, config)
        if(data.alert){
          if(data.alert==="No user found"){setUserdata()}
          setMessage2(data.alert)
        } 
        else {if(data.user){
          setUserdata(data.user); setTotalRequest(data.server.currentsubmitkyc);
          setIDNumber(data.user.idnumber); Uncheck(); Home1(); Home2(); Home3();
          ref_acceptbtn.current.focus()
        }else{setUserdata()}}
      }catch(error){setMessage3(error)}
      setLoader(false)
      setLockbutton(false)
    }else(alert("Slowly...."))
  }
  const Reject = async (user) => { 
    setLoader(true)
    setHistory_Email(userData.email); setHistory_URL(userData.pic1[0] && userData.pic1[0].url?userData.pic1[0].url:""); setHistory_ID(userData.idnumber)
    try {
      const userid = userData._id
      const { data } = await axios.post(`/api/users/reject`, {userid, kycmessage, user}, config)
      if(data.alert){
        if(data.alert==="No user found"){setUserdata()}
        setMessage2(data.alert)
      } 
      else {if(data.user){
        setUserdata(data.user); setTotalRequest(data.server.currentsubmitkyc);
        setIDNumber(data.user.idnumber); Uncheck(); Home1(); Home2(); Home3()
        ref_acceptbtn.current.focus()
      }else{setUserdata()}}
    }catch(error){setMessage3(error)}
    setLoader(false)
    setLockbutton(false)
  }
  const FixNumber = async (user) => { 
    setLoader(true)
    try {
      const userid = userData._id
      const { data } = await axios.post(`/api/users/fixnumber`, {userid, idnumber, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else{Accept()}
    }catch(error){setMessage3(error)}
    setLoader(false)
  }
  const Uncheck = async () => { 
    $('#checkbox1').prop('checked', false); $('#checkbox2').prop('checked', false); $('#checkbox3').prop('checked', false)
    $('#checkbox4').prop('checked', false); $('#checkbox5').prop('checked', false); $('#checkbox6').prop('checked', false)
    $('#checkbox7').prop('checked', false); $('#checkbox0').prop('checked', false)
    setKYCMessage([])
  }

  const handleKeyDown = event => {
    if(event.key==="1" && !lockbutton){setLockbutton(true); AddKYCMessage("It looks like your photos encountered an error during the upload process"); Reject()}
    if(event.key==="2" && !lockbutton){setLockbutton(true); AddKYCMessage("Your ID Card does not display National ID Number - NIN"); Reject()}
    if(event.key==="3" && !lockbutton){setLockbutton(true); AddKYCMessage("The ID document you have provided are not accepted"); Reject()}
    if(event.key==="4" && !lockbutton){setLockbutton(true); AddKYCMessage("The ID Card number you provided is incorrect"); Reject()}
    if(event.key==="5" && !lockbutton){setLockbutton(true); AddKYCMessage("The images do not meet the requirements"); Reject()}
    if(event.key==="6" && !lockbutton){setLockbutton(true); AddKYCMessage("The front of ID photo does not meet the requirements"); Reject()}
    if(event.key==="7" && !lockbutton){setLockbutton(true); AddKYCMessage("The back of ID photo does not meet the requirements"); Reject()}
    if(event.key==="8" && !lockbutton){setLockbutton(true); AddKYCMessage("The selfie photo does not meet the requirement"); Reject()}
    if(event.key==="ArrowLeft"){Right1()}
    if(event.key==="ArrowUp"){Vertical1()}
    if(event.key==="ArrowRight"){Left1()}
    if(event.key==="ArrowDown"){View1()}
  }

  const Right1 = async () => {$(`#image1`).css('transform', 'rotate(90deg)')}
  const Left1 = async () => {$(`#image1`).css('transform', 'rotate(-90deg)')}
  const Vertical1 = async () => {$(`#image1`).css('transform', 'rotate(180deg)')}
  const Horizontal1 = async () => {$(`#image1`).css('transform', 'scaleX(-1)')}
  const Home1 = async () => {$(`.div30-1`).css('width', '33.33%'); $(`.div30-1`).css('margin-left', '0'); $(`#image1`).css('transform', 'scaleX(1) rotate(0)')}
  const View1 = async () => {$(`.div30-1`).css('width', '80%'); $(`.div30-1`).css('margin-left', '10%')}

  const Right2 = async () => {$(`#image2`).css('transform', 'rotate(90deg)')}
  const Left2 = async () => {$(`#image2`).css('transform', 'rotate(-90deg)')}
  const Vertical2 = async () => {$(`#image2`).css('transform', 'rotate(180deg)')}
  const Horizontal2 = async () => {$(`#image2`).css('transform', 'scaleX(-1)')}
  const Home2 = async () => {$(`.div30-2`).css('width', '33.33%'); $(`.div30-2`).css('margin-left', '0'); $(`#image2`).css('transform', 'scaleX(1) rotate(0)')}
  const View2 = async () => {$(`.div30-2`).css('width', '80%'); $(`.div30-2`).css('margin-left', '10%')}

  const Right3 = async () => {$(`#image3`).css('transform', 'rotate(90deg)')}
  const Left3 = async () => {$(`#image3`).css('transform', 'rotate(-90deg)')}
  const Vertical3 = async () => {$(`#image3`).css('transform', 'rotate(180deg)')}
  const Horizontal3 = async () => {$(`#image3`).css('transform', 'scaleX(-1)')}
  const Home3 = async () => {$(`.div30-3`).css('width', '33.33%'); $(`.div30-3`).css('margin-left', '0'); $(`#image3`).css('transform', 'scaleX(1) rotate(0)')}
  const View3 = async () => {$(`.div30-3`).css('width', '80%'); $(`.div30-3`).css('margin-left', '10%')}

return (
    <main>
      <Header>{userInfo ? localStorage.getItem("airdrop"):0}</Header><Sidebar/>
      {loader && <Loader/>}
      {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
      {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
      {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
      {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}

      {history &&
        <div className="confirm-modal">
          <article class="modal-container">
            <header class="modal-container-header">
              <h2 class="modal-container-title">
                Message
              </h2>
              <button class="icon-button" onClick={()=>setHistory(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path fill="currentColor" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </header>

            <section class="modal-container-body rtf">
              <p style={{color:"#F59E0B"}}>{history_email}</p>
              <p style={{color:"#F59E0B"}}>{history_idnumber}</p>
              <img width="100%" src={history_url} alt=""/>
            </section>
            <footer class="modal-container-footer">
            <button class="button is-primary" onClick={()=>setHistory(false)}>Close</button>
            </footer>
          </article>
        </div>
      }
      <div className="main-section submitkycview" style={{paddingBottom:"800px"}}>
        <div style={{marginTop:"70px"}}>&nbsp;</div>

          <div className="header">
            <p>{userData && userData.email}</p>
            <input value={userData && idnumber} onChange={(e)=>setIDNumber(e.target.value.split(' ').join('').trim().replace(/[^a-zA-Z\d]/ig, "").toUpperCase())}/>
            <span style={{color:"yellow"}}>{userData && userData.countkyc}</span>
            <button onClick={()=>FixNumber()}>Fix</button>
            <button className="pre" onClick={()=>setHistory(true)}>PRE</button>
          </div>

          {userData && <div className="image">
            <div className="div30 div30-1">
              <img id="image1" width="100%" src={userData.pic1[0] && userData.pic1[0].url} alt=""/>
              <button className="Right" onClick={()=>Right1()}><FaArrowRotateRight/></button>
              <button className="Left" onClick={()=>Left1()}><FaArrowRotateLeft/></button>
              <button className="Vertical" onClick={()=>Vertical1()}><TbFlipVertical/></button>
              <button className="Horizontal" onClick={()=>Horizontal1()}><TbFlipHorizontal/></button>
              <button className="Home" onClick={()=>{Home1(); copy(userData.pic1[0] && userData.pic1[0].url)}}><ImHome/></button>
              <button className="GrView" onClick={()=>View1()}><GrView/></button>
              <p>{userData && idnumber}</p>
            </div>
            <div className="div30 div30-2">
              <img id="image2" width="100%" src={userData.pic2[0] && userData.pic2[0].url} alt=""/>
              <button className="Right" onClick={()=>Right2()}><FaArrowRotateRight/></button>
              <button className="Left" onClick={()=>Left2()}><FaArrowRotateLeft/></button>
              <button className="Vertical" onClick={()=>Vertical2()}><TbFlipVertical/></button>
              <button className="Horizontal" onClick={()=>Horizontal2()}><TbFlipHorizontal/></button>
              <button className="Home" onClick={()=>{Home2(); copy(userData.pic2[0] && userData.pic2[0].url)}}><ImHome/></button>
              <button className="GrView" onClick={()=>View2()}><GrView/></button>
              <p>{userData && idnumber}</p>
            </div>
            <div className="div30 div30-3">
              <img id="image3" width="100%" src={userData.pic3[0] && userData.pic3[0].url} alt=""/>
              <button className="Right" onClick={()=>Right3()}><FaArrowRotateRight/></button>
              <button className="Left" onClick={()=>Left3()}><FaArrowRotateLeft/></button>
              <button className="Vertical" onClick={()=>Vertical3()}><TbFlipVertical/></button>
              <button className="Horizontal" onClick={()=>Horizontal3()}><TbFlipHorizontal/></button>
              <button className="Home" onClick={()=>{Home3(); copy(userData.pic3[0] && userData.pic3[0].url)}}><ImHome/></button>
              <button className="GrView" onClick={()=>View3()}><GrView/></button>
              <p>{userData && idnumber}</p>
            </div>
          </div>}

          <div className="fullscreen">
            <div className="button">
              <div>
                <span className="error">
                  <input type="checkbox" id="checkbox0" value="It looks like your photos encountered an error during the upload process" onChange={(e)=>AddKYCMessage(e.target.value)}/>
                  <span class="checkmark" onClick={()=>{
                    AddKYCMessage("It looks like your photos encountered an error during the upload process");
                    Reject()
                  }}>1.ERR</span>
                </span>

                <span className="error">
                  <input type="checkbox" id="checkbox1" value="Your ID Card does not display National ID Number - NIN" onChange={(e)=>AddKYCMessage(e.target.value)}/>
                  <span class="checkmark" onClick={()=>{
                    AddKYCMessage("Your ID Card does not display National ID Number - NIN");
                    Reject()
                  }}>2.NIN</span>
                </span>

                <span className="error">
                  <input type="checkbox" id="checkbox2" value="The ID document you have provided are not accepted" onChange={(e)=>AddKYCMessage(e.target.value)}/>
                  <span class="checkmark" onClick={()=>{
                    AddKYCMessage("The ID document you have provided are not accepted");
                    Reject()
                  }}>3.DOC</span>
                </span>

                <span className="error">
                  <input type="checkbox" id="checkbox3" value="The ID Card number you provided is incorrect" onChange={(e)=>AddKYCMessage(e.target.value)}/>
                  <span class="checkmark" onClick={()=>{
                    AddKYCMessage("The ID Card number you provided is incorrect");
                    Reject()
                  }}>4.NUM</span>
                </span>

                <span className="error">
                  <input type="checkbox" id="checkbox4" value="The images do not meet the requirements" onChange={(e)=>AddKYCMessage(e.target.value)}/>
                  <span class="checkmark" onClick={()=>{
                    AddKYCMessage("The images do not meet the requirements");
                    Reject()
                  }}>5.ALL</span>
                </span>

                <span className="error">
                  <input type="checkbox" id="checkbox5" value="The front of ID photo does not meet the requirements" onChange={(e)=>AddKYCMessage(e.target.value)}/>
                  <span class="checkmark" onClick={()=>{
                    AddKYCMessage("The front of ID photo does not meet the requirements");
                    Reject()
                  }}>6.IMG-1</span>
                </span>

                <span className="error">
                  <input type="checkbox" id="checkbox6" value="The back of ID photo does not meet the requirements" onChange={(e)=>AddKYCMessage(e.target.value)}/>
                  <span class="checkmark" onClick={()=>{
                    AddKYCMessage("The back of ID photo does not meet the requirements");
                    Reject()
                  }}>7.IMG-2</span>
                </span>

                <span className="error">
                  <input type="checkbox" id="checkbox7" value="The selfie photo does not meet the requirements" onChange={(e)=>AddKYCMessage(e.target.value)}/>
                  <span class="checkmark" onClick={()=>{
                    AddKYCMessage("The selfie photo does not meet the requirement");
                    Reject()
                  }}>8.IMG-3</span>
                </span>

              </div>
              <span style={{color:"#fff"}}>{totalrequest}</span>
              <button onClick={()=>Reject()}>Reject</button>
              <button ref={ref_acceptbtn} id="accept-btn" onKeyDown={handleKeyDown} tabIndex={0} onClick={()=>Accept()}>Confirm</button>
            </div>
          </div>

      </div>

    </main>

)
}


export default Verify
