import React, { useState, useEffect } from "react"
const Modal1 = (props) => {
  const closeModal = async () => {props.closeModal(true)}

  const [text, setText] = useState([])

  useEffect(() => {
    if(props.message){setText(props.message)}
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal1 unselectable">

        <div id="success-box">
          <div className="dot"></div><div className="dot two"></div><div className="dot three"></div>
          <div className="header">
            <div className="face">
              <div className="eye success-eye"></div>
              <div className="eye success-eye right"></div>
              <div className="mouth happy"></div>
            </div>
            <div className="shadow scale"></div>
          </div>


          <div className="message">
            <h1 className="alert">SUCCSESS</h1>
            <p>{text}</p>
          </div>
          <p className="button-box" onClick={()=>closeModal()}><span className="green">continue</span></p>
        </div>

    </div>
  )
}

export default Modal1