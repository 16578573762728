import React, { useState, useEffect } from "react"
import ImageSVG from "../../abis/ImageSVG.json"

const Announcement = (props) => {
  const Close = async () => {props.action({close: true})}
  const Dontshow = async () => {props.action({dontshow: true})}
  const [text, setText] = useState([])

  useEffect(() => {
    if(props.message){setText((props.message.toString()).split("/") )}
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="confirm-modal unselectable">

        <article class="modal-container">
          <header class="modal-container-header">
            <h2 class="modal-container-title">
              <img src={ImageSVG.greenlogo} alt=""/>
              Announcement
            </h2>
            <button class="icon-button" onClick={()=>Close()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
              </svg>
            </button>
          </header>

          <section class="modal-container-body rtf">
            {text && text.map((i) => (<p>{i}</p>))}
          </section>
          <footer class="modal-container-footer">
            <button class="button is-primary" onClick={()=>Dontshow()}>Don't show again</button>
          </footer>
        </article>

    </div>
  )
}

export default Announcement