import React, { useState, useEffect } from "react"
import axios from "axios" 
import { Formik } from "formik"
import * as Yup from "yup"
import Particles from "react-tsparticles"
import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"

import { MdOutlineAlternateEmail } from "react-icons/md"
import { TbPasswordFingerprint } from "react-icons/tb"

const Password = () => {
  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState(''); const [message2, setMessage2] = useState('')
  const [message3, setMessage3] = useState(''); const [message4, setMessage4] = useState('')
  const [errorEmail, setErrorEmail] = useState("")
  const [errorPassword, setErrorPassword] = useState("")
  const [isForgotPWBTN, setIsForgotPWBTN] = useState(true)

  const userInfo = localStorage.getItem("greennetwork")
  useEffect(() => {if (userInfo){window.location.href="/"}})// eslint-disable-line react-hooks/exhaustive-deps

  const path = window.location.pathname
  useEffect(()=>{
    if(path.slice(1,5)==="grc-"){
      Active(path.slice(5,path.length))
    }
  },[])// eslint-disable-line react-hooks/exhaustive-deps

  const Active = async (code) => {
    setLoader(true)
    try {
        const {data} = await axios.post(`/api/users/active`, {code}, {headers: {"Content-type": "application/json"}})
        if(data.alert){
          setMessage2(data.alert)
      }else{
          setMessage1(data.message)
      }
    } catch (error) {setMessage3("Incorrect or expired link")}
    setLoader(false)
  }
  const Password = async (email) => {
    setLoader(true)
    try {
      const {data} = await axios.post(`/api/users/password`, {email}, {headers: {"Content-type": "application/json"}})
      if(data.alert){setMessage2(data.alert)}
      else if(data.errorEmail){setErrorEmail(data.errorEmail)}
      else{
        setMessage1(data.message)
        setIsForgotPWBTN(false)
      }
    } catch (error) {setMessage4(error)}
    setLoader(false)
  }

return (
  <Formik
    initialValues={{ email: "", password: "" }}
    onSubmit={async(values) => {
      const email = values.email; const password = values.password
      setLoader(true)
      try {
          const {data} = await axios.post(`/api/users/login`, {email, password}, {headers: {"Content-type": "application/json"}})
          if(data.alert){setMessage2(data.alert)}
          else if(data.errorEmail){setErrorEmail(data.errorEmail)}
          else if(data.errorPassword){setErrorPassword(data.errorPassword)
          }else{
            localStorage.setItem("greennetwork", data.token)
            window.location.href = '/'
          }
      } catch (error) {setMessage4(error)}
      setLoader(false)
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email("Must be a valid email")
        .max(50, "Maximum 50 characters")
        .required("Required"),
      password: Yup.string()
        .required("Required")
        .min(8, "Minimum 8 characters")
        .max(20, "Maximum 20 characters")
    })}
  >
  {props => {
    const {values, touched, errors, handleChange, handleBlur, handleSubmit} = props
  return (
    <main>
      <Header/><Sidebar/>
      {loader && <Loader/>}
      {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
      {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
      {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
      {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}

      <div className="main-section">

        <div className="Particles">
          <Particles
            options={{
              fullScreen: {enable: false},
              particles: {
                number: {value: 50, density: {enable: true, value_area: 1200}},
                color: {value: "#10B981"},
                shape: {
                  type: "circle",
                  stroke: {width: 0, color: "#10B981"},
                  polygon: {nb_sides: 3},
                },
                opacity: {value: 0.5, random: true, anim: {enable: true, speed: 1, opacity_min: 0.5, sync: false}},
                size: {value: 30, random: true,
                  anim: {enable: true, speed: 10, size_min: 10, sync: false}
                },
                line_linked: {enable: true, distance: 100, color: "#10B981", opacity: 1, width: 1},
                move: {enable: true,speed: 3,direction: "none",random: false,straight: false,out_mode: "out",bounce: false,attract: {enable: false,rotateX: 600,rotateY: 1200}}
              },
            }}
          />
        </div>

        <div className="loginform">

          <button className="switch-btn switch-login-btn active" disabled>LOGIN</button>
          <button className="switch-btn switch-register-btn" onClick={()=>window.location.href="/register"}>REGISTER</button>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label><i><MdOutlineAlternateEmail/></i>
                <input autoFocus name="email" type="text" placeholder="Enter your email" onFocus={()=>{if(errorEmail){setErrorEmail("")}}}
                  value={values.email.split(' ').join('').trim().toLowerCase()} onChange={handleChange} onBlur={handleBlur}
                  className={errors.email && touched.email ? "error" : errorEmail && "error"}
                />
                {errors.email && touched.email ? (<p>{errors.email}</p>) : errorEmail && (<p>{errorEmail}</p>)}
              </div>

              <div className="form-group">
                <label htmlFor="email">Password</label><i><TbPasswordFingerprint/></i>
                <input name="password" type="password" placeholder="Enter your password" onFocus={()=>{if(errorPassword){setErrorPassword("")}}}
                  value={values.password.split(' ').join('').trim()} onChange={handleChange} onBlur={handleBlur}
                  className={errors.password && touched.password ? "error" : errorPassword && "error"}
                />
                {errors.password && touched.password ? (<p>{errors.password}</p>) : errorPassword && (<p>{errorPassword}</p>)}
              </div>
              
              <div className="text-center"><button className="normal-btn" >LOG IN</button></div>
                <p className="mb-0 mt-4 text-center">
                  {isForgotPWBTN ?
                    <a className="forgotpw-btn" onClick={()=>{
                      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      if (re.test(values.email.split(' ').join('').trim().toLowerCase())) {
                        Password(values.email.split(' ').join('').trim().toLowerCase())
                      } else {
                        setErrorEmail("Must be a valid email")
                      }
                    }}>Send password to email</a>:
                    <a className="forgotpw-btn">Please check your inbox</a>
                  }

                </p>
            </form>
            
          </div>
      </div>

      <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
      </svg>
    </div>
  </main>
  )
  }}
  </Formik>
)
}

export default Password
