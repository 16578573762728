import React, { useState, useEffect } from "react"
import axios from "axios" 
import { Formik } from "formik"
import * as Yup from "yup"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"

import { TbPasswordFingerprint } from "react-icons/tb"
import { IoSettingsOutline } from "react-icons/io5"

const Security = () => {
  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState(''); const [message2, setMessage2] = useState('')
  const [message3, setMessage3] = useState(''); const [message4, setMessage4] = useState('')

  const [myemail, setEmail] = useState("")
  const [verified, setVerified] = useState(false)
  const [showChangeEmail, setShowChangeEmail] = useState(false)
  const [isChangeEMAILBTN, setIsChangeEMAILBTN] = useState(false)
  const [isChangePWBTN, setIsChangePWBTN] = useState(false)
  const [errorEmail, setErrorEmail] = useState("")

  const userInfo = localStorage.getItem("greennetwork")
  const config = {headers: {"Content-Type": "application/json", Authorization: `Bearer ${userInfo && userInfo}`}}
  useEffect(() => {if (userInfo){getUser()}}, [])

  const getUser = async (user) => {
    setLoader(true)
    try{
      const { data } = await axios.post(`/api/users/getuser`, user, config)
      if(data.alert){setMessage2(data.alert)} 
      else {setEmail(data.user.email); setVerified(data.user.verifyemail)}
    }catch(error){setMessage4(error)}
    setLoader(false)
  }


  const logOut = async(childData) => {if(childData){window.location.href="/login"}}
return (<>
  <main>
    <Header logOut={logOut}/><Sidebar logOut={logOut}/>
    {loader && <Loader/>}
    {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
    {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
    {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
    {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}
    <div className="main-section">
      {userInfo && <div className="loginform">

        <div className="authentication">
          <h3>Two-Factor Authentication</h3>
          <p><img src={require("./images/authentication.png")} alt=""/> Google Authenticator <i className="set"><IoSettingsOutline/></i></p>
          <p><img src={require("./images/email.png")} alt=""/> Email: {myemail}</p>
          
        </div>
        <div className="verifyemail-btn"><button disabled={isChangeEMAILBTN} onClick={()=>setShowChangeEmail(true)}>Change email</button></div>

        {showChangeEmail &&
        <Formik
          initialValues={{ email: ""}}
          onSubmit={async(values, user) => {
            const email = values.email
            setLoader(true)
            try {
                const {data} = await axios.post(`/api/users/verifyemail`, {user, email}, config)
                if(data.errorEmail){setErrorEmail(data.errorEmail)}
                else if(data.alert){setMessage2(data.alert)}
                else{
                  setMessage1(data.message)
                  setEmail(data.user.email)
                  localStorage.setItem("greennetwork", data.token)
                  setIsChangeEMAILBTN(true)
                }
            } catch (error) {setMessage4(error)}
            setLoader(false)
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
            .email("Must be a valid email")
            .required("Required")
            .max(50, "Maximum 50 characters"),
          })}
        >
        {props => {
          const {values, touched, errors, handleChange, handleBlur, handleSubmit} = props

        return (<>        
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group" style={{marginTop:"0"}}>
                <label htmlFor="email">New Email</label><i><TbPasswordFingerprint/></i>
                <input name="email" type="email" placeholder="Enter new email"
                  value={values.email} onChange={handleChange} onBlur={handleBlur} onFocus={()=>{if(errorEmail){setErrorEmail("")}}}
                  className={errors.email && touched.email && "error"}
                />
                {errors.email && touched.email ? (<p>{errors.email}</p>) : errorEmail && (<p>{errorEmail}</p>)}
              </div>
              <div className="text-center"><button className="normal-btn" style={{fontSize:"14px", marginTop:"10px"}} disabled={isChangeEMAILBTN}>SUBMIT</button></div>
            </form>
          </div>
          </>)}}</Formik>
          }

        <Formik
          initialValues={{ password: "", confirmpassword: "" }}
          onSubmit={async(values, user) => {
            const password = values.password
            setLoader(true)
            try {
                const {data} = await axios.post(`/api/users/changepw`, {user, password}, config)
                if(data.alert){setMessage2(data.alert)}
                else{
                  setMessage1(data.message)
                  setIsChangePWBTN(true)
                }
            } catch (error) {setMessage4(error)}
            setLoader(false)
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required("Required")
              .min(8, "Minimum 8 characters")
              .max(20, "Maximum 20 characters"),
            confirmpassword: Yup.string()
              .required("Required")
              .oneOf([Yup.ref('password')], 'Passwords must match')
          })}
        >
        {props => {const {values, touched, errors, handleChange, handleBlur, handleSubmit} = props
        return (<>
          <div className="authentication" style={{marginTop:"20px"}}><p><img src={require("./images/lock.png")} alt=""/> Login Password</p></div>
          
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group" style={{marginTop:"0"}}>
                <label htmlFor="password">New Password</label><i><TbPasswordFingerprint/></i>
                <input name="password" type="password" placeholder="Enter new password"
                  value={values.password.split(' ').join('').trim()} onChange={handleChange} onBlur={handleBlur}
                  className={errors.password && touched.password && "error"}
                />
                {errors.password && touched.password && (<p>{errors.password}</p>)}
              </div>
              
              <div className="form-group">
                <label htmlFor="confirmpassword">Re-Password</label><i><TbPasswordFingerprint/></i>
                <input name="confirmpassword" type="password" placeholder="Confirm new password"
                  value={values.confirmpassword.split(' ').join('').trim()} onChange={handleChange} onBlur={handleBlur}
                  className={errors.confirmpassword && touched.confirmpassword && "error"}
                />
                {errors.confirmpassword && touched.confirmpassword && (<p>{errors.confirmpassword}</p>)}
              </div>
              
              <div className="text-center"><button className="normal-btn" style={{fontSize:"14px", marginTop:"10px"}} disabled={isChangePWBTN}>SUBMIT</button></div>
            </form>
          </div>
          </>)}}</Formik>
        </div>}
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
      </svg>
    </div>
  </main>
</>)
}

export default Security
