import React, { useState, useEffect } from "react"
import { IoMdCloseCircle } from "react-icons/io"

import { FaHeart } from "react-icons/fa"
import { RiChat1Fill } from "react-icons/ri"
import { PiUploadSimpleBold } from "react-icons/pi"

const Twitter = (props) => {
  const closeModal = async () => {props.closeModal(true)}

  const [link, setLink] = useState("")
  const [account, setAccount] = useState("")

  useEffect(() => {
    if(props.message){
      setLink(props.message)
      const array = (props.message).split("com/")
      setAccount("@"+array[1])
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="twitter unselectable">
        <div id="transparent-box">
          <span className="twitter-info d-flex align-items-center">
            <img src={require("../images/squarelogo64.png")} alt=""/>
            <span className="text">
              <big><b>Green Network</b><img src={require("../images/verify.png")} alt=""/></big>
              <div className="middle"></div>
              <big><span className="grey">{account}</span> <a onClick={()=>localStorage.setItem("twitterNotice", true)} href={link} target="_blank" rel="noopener noreferrer">Follow</a></big>
            </span>
          </span>
          <div>Welcome to Green Network</div>
          <div className="time">7:00 AM · Mar 01, 2024</div>

          <div className="line"></div>

          <div className="button">
            <a onClick={()=>localStorage.setItem("twitterNotice", true)} className="love" href={link} target="_blank" rel="noopener noreferrer"><FaHeart className="icon"/>Love</a>
            <a onClick={()=>localStorage.setItem("twitterNotice", true)} className="reply" href={link} target="_blank" rel="noopener noreferrer"><RiChat1Fill className="icon"/>Reply</a>
            <a onClick={()=>localStorage.setItem("twitterNotice", true)} className="share" href={link} target="_blank" rel="noopener noreferrer"><PiUploadSimpleBold className="icon"/>Share</a>
          </div>
          
          <a onClick={()=>localStorage.setItem("twitterNotice", true)} className="follow" href={link} target="_blank" rel="noopener noreferrer">View profile on X</a>
          <div className="close" onClick={()=>{localStorage.setItem("twitterNotice", true); closeModal()}}><IoMdCloseCircle/></div>
        </div>

    </div>
  )
}

export default Twitter