import React, { useState, useEffect } from "react"
import axios from "axios"
import Particles from "react-tsparticles"

import Header from "./Header"
import Sidebar from "./Sidebar"
import SaleModal from "./Modal/saleModal"
import Twitter from "./Modal/twitter"
import Telegram from "./Modal/telegram"
import Announcement from "./Modal/announcement"
import BuyBack from "./BuyBack"
import pancakeswap from "./images/pancakeswap.png"
const Home = () => {
  const logOut = async(childData) => {if(childData){console.log("logOut")}}

  const [notice, setNotice] = useState("")
  const [announcement, setAnnouncement] = useState("")

  const [twitter, setTwitter] = useState("")
  const [telegram, setTelegram] = useState("")
  const [discord, setDiscord] = useState("")
  const [linkedin, setLinkedin] = useState("")

  const saleNotice = localStorage.getItem("saleNotice")
  const twitterNotice = localStorage.getItem("twitterNotice")
  const telegramNotice = localStorage.getItem("telegramNotice")

  const [message, setMessage] = useState("")
  const Alert = async (message) => {
      setMessage(message)
      setTimeout(() => {setMessage("")}, 1000)
  }

  useEffect(() => {getServer()}, [])// eslint-disable-line react-hooks/exhaustive-deps
  const getServer = async () => {
    try{
      const { data } = await axios.post(`/api/users/getserver`, {headers: {"Content-type": "application/json"}})
      setNotice(data.server.notice)
      if(!data.server.announcement){localStorage.removeItem("dontshowagain")}
      setAnnouncement(data.server.announcement)
      setTwitter(data.server.twitter)
      setTelegram(data.server.telegram)
      setDiscord(data.server.discord)
      setLinkedin(data.server.linkedin)
    }catch(error){console.log(error)}
  }
return (<>

  <main>
    <Header logOut={logOut}/><Sidebar logOut={logOut}/>

    {notice && notice==="ico" && !saleNotice && <SaleModal closeModal={async(close) => {if(close){setNotice("")}}}/>}
    {notice && twitter && !twitterNotice && notice==="twitter" && <Twitter closeModal={async(close) => {if(close){setNotice("")}}} message={twitter}/>}
    {notice && telegram && !telegramNotice && notice==="telegram" && <Telegram closeModal={async(close) => {if(close){setNotice("")}}} message={telegram}/>}

    {announcement && !localStorage.getItem("dontshowagain") && <Announcement action={async(action) => {
      if(action.close){setAnnouncement("")}; if(action.dontshow){localStorage.setItem("dontshowagain", true); setAnnouncement("")}
    }} message={announcement}/>}

    {notice && notice==="buyback" && <BuyBack closeModal={async(close) => {if(close){setNotice("")}}} message={telegram}/>}

    <section className="hero-section d-flex justify-content-center align-items-center">
      <div className="section-overlay"/>


        <div className="Particles">
          <Particles
            options={{
              fullScreen: {enable: false},
              particles: {
                number: {value: 50, density: {enable: true, value_area: 1200}},
                color: {value: "#10B981"},
                shape: {
                  type: "circle",
                  stroke: {width: 0, color: "#10B981"},
                  polygon: {nb_sides: 3},
                },
                opacity: {value: 0.5, random: true, anim: {enable: true, speed: 1, opacity_min: 0.5, sync: false}},
                size: {value: 30, random: true,
                  anim: {enable: true, speed: 10, size_min: 10, sync: false}
                },
                line_linked: {enable: true, distance: 100, color: "#10B981", opacity: 1, width: 1},
                move: {enable: true,speed: 3,direction: "none",random: false,straight: false,out_mode: "out",bounce: false,attract: {enable: false,rotateX: 600,rotateY: 1200}}
              },
            }}
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 mb-3 mb-lg-0">
              <h2 className="text-white">Welcome to</h2>
              <h1 className="cd-words-wrapper">GREEN NETWORK</h1><br/>
              <h2 className="text-white">Listing time: 01/10/2024</h2>
              <img src={pancakeswap} alt="" style={{width:"30%"}}/>
              {/*<button className="btn-googleplay">GOOGLE PLAY</button>*/}
            </div>

            <div className="col-lg-6 col-12">
              <div className="app-container">
                <img id="app" src={require('./images/image3.png')} alt=""/>
                <div id="ethereum1"></div>
                <div id="ethereum2"></div>
              </div>
            </div>

          </div>
        </div>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#ffffff" fillOpacity={1} d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
      </svg>
    </section>


    <section className="about-section section-padding" id="section_2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12 text-center">
            <h2 className="mb-lg-5 mb-4">About Green Network</h2>
          </div>
          <div className="col-lg-5 col-12 me-auto mb-4 mb-lg-0">
            <h3 className="mb-3">A Decentralised Network</h3>
            <p>
            The Green Network, as the cornerstone of Web 3.0, would become the new gateway to the Web 3.0 world by assuring the freedom of individual choice, giving participation, and making the Web3.0 world accessible to each participant.
            </p>
            <button className="readmore-btn" onClick={()=>window.location.href="/whitepaper"}>Read more</button>
          </div>
          <div className="col-lg-5 col-md-6 col-12 mb-4 mb-lg-0 mb-md-0">
          <div className='animation-example'>
            <div className='item'>
                <div className='line'></div>
                <div className='dot'></div>
                <div className='circle'></div>
            </div>
            <div className='item'>
                <div className='line'></div>
                <div className='dot'></div>
                <div className='circle'></div>
            </div>
            <div className='item'>
                <div className='line'></div>
                <div className='dot'></div>
                <div className='circle'></div>
            </div>
            <div className='item'>
                <div className='line'></div>
                <div className='dot'></div>
                <div className='circle'></div>
            </div>
            <div className='item -type2'>
                <div className='line'></div>
                <div className='dot'></div>
                <div className='circle'></div>
            </div>
            <div className='item -type2'>
                <div className='line'></div>
                <div className='dot'></div>
                <div className='circle'></div>
            </div>
            <div className='item -type2'>
                <div className='line'></div>
                <div className='dot'></div>
                <div className='circle'></div>
            </div>
            <div className='item -type2'>
                <div className='line'></div>
                <div className='dot'></div>
                <div className='circle'></div>
            </div>
            <div className='center'>
                <div className='circle'></div>
                <div className='circle'></div>
            </div>
        </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-bg-image">
      <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path fill="rgba(255, 255, 255, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" strokeWidth={0}/>{" "}
        <path fill="rgba(255, 255, 255, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" strokeWidth={0}/>{" "}
        <path fill="rgba(255, 255, 255, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" strokeWidth={0}/>
        <path fill="rgba(255, 255, 255, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" strokeWidth={0}/>
        <path fill="rgba(255, 255, 255, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" strokeWidth={0}/>
      </svg>
      <div className="container" style={{position:"relative"}}>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="section-bg-image-block">
              <h2 className="mb-lg-3">GRC Airdrop</h2>
              <p>
                This is the reward for pioneers who have joined our project and helped us spread the project to the community. 
                Quickly register an account and receive rewards every day. Don't miss this rare opportunity.
              </p>
              <button className="readmore-btn" onClick={()=>window.location.href="/airdrop"}>Get Airdrop</button>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div className="scene">
              <div className="cube-wrapper">
                <div className="cube">
                  <div className="cube-faces">
                    <div className="cube-face shadow"></div>
                    <div className="cube-face bottom"></div>
                    <div className="cube-face top"></div>
                    <div className="cube-face left"></div>
                    <div className="cube-face right"></div>
                    <div className="cube-face back"></div>
                    <div className="cube-face front"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <svg viewBox="0 0 1265 144" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path fill="rgba(255, 255, 255, 1)" d="M 0 40 C 164 40 164 20 328 20 L 328 20 L 328 0 L 0 0 Z" strokeWidth={0}/>{" "}
        <path fill="rgba(255, 255, 255, 1)" d="M 327 20 C 445.5 20 445.5 89 564 89 L 564 89 L 564 0 L 327 0 Z" strokeWidth={0}/>{" "}
        <path fill="rgba(255, 255, 255, 1)" d="M 563 89 C 724.5 89 724.5 48 886 48 L 886 48 L 886 0 L 563 0 Z" strokeWidth={0}/>
        <path fill="rgba(255, 255, 255, 1)" d="M 885 48 C 1006.5 48 1006.5 67 1128 67 L 1128 67 L 1128 0 L 885 0 Z" strokeWidth={0}/>
        <path fill="rgba(255, 255, 255, 1)" d="M 1127 67 C 1196 67 1196 0 1265 0 L 1265 0 L 1265 0 L 1127 0 Z" strokeWidth={0}/>
      </svg>
    </section>

    <section className="about-section section-padding" id="section_2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12 text-center">
            <h2 className="mb-lg-5 mb-4">Airdrop distribution</h2>
          </div>
          <div className="col-lg-5 col-12 me-auto mb-4 mb-lg-0">
            <h3 className="mb-3">Do I have to pay any fees?</h3>
            <p>
              You will not have to pay any fees to receive your coins. 
              At the appropriate time, we will distribute GRC to the address where you will have to provide it to us. 
              However, you and your referrals will have to verify identity (KYC). The rewards you received from referrals who did not complete KYC will be erased.
            </p>
          </div>
          <div className="col-lg-5 col-md-6 col-12 mb-4 mb-lg-0 mb-md-0">
            <div className="">
              <div className="kycbackground">
                <img className="bgimage" src={ require('./images/kyc.png')} alt=""/>
                <img className="kycbar" src={ require('./images/kycbar.png')} alt=""/>
                <div className = "eye"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section className="events-section section-bg section-padding" id="roadmap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12"><h2 className="mb-lg-3">Roadmap</h2></div>

          <div className="row custom-block mb-3">
            <div className="col-lg-2 col-md-4 col-12 order-2 order-md-0 order-lg-0">
              <div className="custom-block-date-wrap d-flex d-lg-block d-md-block align-items-center mt-3 mt-lg-0 mt-md-0">
                <h6 className="custom-block-date mb-lg-1 mb-0 me-3 me-lg-0 me-md-0">Phase 1</h6>
                <strong className="text-white">Q2 2024</strong>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-12 order-1 order-lg-0">
              <div className="custom-block-image-wrap"><img src={require("./images/roadmap1.png")} className="img-fluid" alt=""/></div>
            </div>
            <div className="col-lg-6 col-12 order-3 order-lg-0">
              <p className="mb-1">Establish the foundation of Green Network</p>
              <p className="mb-1">Grow the community</p>
              <p className="mb-1">Launch airdrop, referral system</p>
              <p className="mb-1">Open KYC verification</p>
              <p className="mb-1">Complete the Green Network White Paper</p>
              <p className="mb-1">Complete private placement</p>
            </div>
          </div>

          <div className="row custom-block custom-block-bg">
            <div className="col-lg-2 col-md-4 col-12 order-2 order-md-0 order-lg-0">
              <div className="custom-block-date-wrap d-flex d-lg-block d-md-block align-items-center mt-3 mt-lg-0 mt-md-0">
                <h6 className="custom-block-date mb-lg-1 mb-0 me-3 me-lg-0 me-md-0">Phase 2</h6>
                <strong className="text-white">Q3 2024</strong>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-12 order-1 order-lg-0">
              <div className="custom-block-image-wrap"><img src={require("./images/roadmap2.png")} className="img-fluid" alt=""/></div>
            </div>
            <div className="col-lg-6 col-12 order-3 order-lg-0">
              <p className="mb-1">Complete identity verification for all users</p>
              <p className="mb-1">Distribute airdrops to users</p>
              <p className="mb-1">Release ERC20 Token (GRC)</p>
              <p className="mb-1">Pre-sale of GRC Token</p>
              <p className="mb-1">Launch the staking system</p>
            </div>
          </div>

          <div className="row custom-block mb-3">
            <div className="col-lg-2 col-md-4 col-12 order-2 order-md-0 order-lg-0">
              <div className="custom-block-date-wrap d-flex d-lg-block d-md-block align-items-center mt-3 mt-lg-0 mt-md-0">
                <h6 className="custom-block-date mb-lg-1 mb-0 me-3 me-lg-0 me-md-0">Phase 3</h6>
                <strong className="text-white">Q4 2024</strong>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-12 order-1 order-lg-0">
              <div className="custom-block-image-wrap"><img src={require("./images/roadmap3.png")} className="img-fluid" alt=""/></div>
            </div>
            <div className="col-lg-6 col-12 order-3 order-lg-0">
              <p className="mb-1">List token on Coinmartketcap, Coingecko</p>
              <p className="mb-1">List token on DEX, CEX</p>
              <p className="mb-1">Release Green Network Beta 2.0 version</p>
              <p className="mb-1">Provide node services for BNB</p>
              <p className="mb-1">Provide node services for Ethereum</p>
              <p className="mb-1">Release Green Market</p>
            </div>
          </div>

          <div className="row custom-block custom-block-bg">
            <div className="col-lg-2 col-md-4 col-12 order-2 order-md-0 order-lg-0">
              <div className="custom-block-date-wrap d-flex d-lg-block d-md-block align-items-center mt-3 mt-lg-0 mt-md-0">
                <h6 className="custom-block-date mb-lg-1 mb-0 me-3 me-lg-0 me-md-0">Phase 4</h6>
                <strong className="text-white">Q1 2025</strong>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-12 order-1 order-lg-0">
              <div className="custom-block-image-wrap"><img src={require("./images/roadmap4.png")} className="img-fluid" alt=""/></div>
            </div>
            <div className="col-lg-6 col-12 order-3 order-lg-0">
              <p className="mb-1">Release the GreenSDK</p>
              <p className="mb-1">Release the Green Network, GRC Coin</p>
              <p className="mb-1">Start to build DAO</p>
              <p className="mb-1">Connect more traditional infrastructure service for Green Network</p>
              <p className="mb-1">Hand over Green Network</p>
            </div>
          </div>


        </div>
      </div>
    </section>
    <section className="contact-section section-padding" id="section_5">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-12">
            <form className="custom-form contact-form">
              <h2 className="mb-4 pb-2">How can we help?</h2>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      name="full-name"
                      id="full-name"
                      className="form-control"
                      placeholder="Full Name"
                      required
                    />
                    <label htmlFor="floatingInput">Full Name</label>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-floating">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      pattern="[^ @]*@[^ @]*"
                      className="form-control"
                      placeholder="Email address"
                      required
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      placeholder="Describe message here"
                      defaultValue={""}
                      required
                    />
                    <label htmlFor="floatingTextarea">Message</label>
                  </div>
                  <button type="submit" className="form-control">
                    Submit Form
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="col-lg-6 col-12">
            <div className="contact-info mt-5">
              <div className="contact-info-item">
                <div className="contact-info-body"><strong>Contact us vie Email</strong></div>
                <div className="contact-info-footer">
                    <a href="mailto:contact@grcoiner.io" className="contact-link">contact@grcoiner.io</a>
                </div>
              </div>
              <img src={require("./images/WorldMap.png")} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <footer className="site-footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-12 me-auto mb-5 mb-lg-0">
          <a className="navbar-brand d-flex align-items-center" href="index.html">
            <img src={require("./images/squarelogo64.png")} className="navbar-brand-image img-fluid" alt=""/>
            <span className="navbar-brand-text" style={{color:"#015338", marginLeft:"5px"}}>
              <big>GREEN</big>
              <div className="middle" style={{background:"#015338"}}></div>
              <small>Network</small>
            </span>
          </a>
        </div>

        <div className="col-lg-3 col-12 ms-auto social-media">
          {message && <div className="social-media-alert"><i class="bi bi-shield-fill-exclamation"></i></div>}
          <ul className="social-icon mt-3 mb-4">
            <li className="social-icon-item">
              {twitter ?  <a href={twitter} target="_blank" rel="noopener noreferrer" className="social-icon-link bi-twitter"/>:
                          <a className="social-icon-link bi-twitter" onClick={()=>Alert("Comming soon")}/>
              }
            </li>
            <li className="social-icon-item">
              {discord ?  <a href={discord} target="_blank" rel="noopener noreferrer" className="social-icon-link bi-discord"/>:
                          <a className="social-icon-link bi-discord" onClick={()=>Alert("Comming soon")}/>
              }
            </li>
            <li className="social-icon-item">
              {linkedin ?   <a href={linkedin} target="_blank" rel="noopener noreferrer" className="social-icon-link bi-linkedin"/>:
                            <a className="social-icon-link bi-linkedin" onClick={()=>Alert("Comming soon")}/>
              }
            </li>
            <li className="social-icon-item">
              <a className="social-icon-link bi-telegram" onClick={()=>Alert("Comming soon")}/>
            </li>
            <li className="social-icon-item">
              <a className="social-icon-link bi-github" onClick={()=>Alert("Comming soon")}/>
            </li>
          </ul>

          <p className="copyright-text">Copyright ©2024 GreenLabs</p>

        </div>
      </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#0F7959" fillOpacity={1}
        d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
      />
    </svg>
  </footer>

</>)
}


export default Home
