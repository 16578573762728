import React, { useState, useEffect } from "react"
import { FiAlertTriangle } from "react-icons/fi"

const Modal3 = (props) => {
  const closeModal = async () => {props.closeModal(true)}

  const [text, setText] = useState("")

  useEffect(() => {
    if(props.message){setText(props.message.toString())}
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal1 unselectable">
        <div id="alert-box">
          <div className="dot"></div><div className="dot two"></div><div className="dot three"></div>
          <div className="header">
            <div className="face3">
              <div className="icon"><FiAlertTriangle /></div>
            </div>
          </div>

          <div className="message">
            <h1 className="alert">SERVER ERROR</h1>
            <p>{text}</p>
          </div>
          <p className="button-box" onClick={()=>closeModal()}><span className="red">TRY AGAIN</span></p>
        </div>
    </div>
  )
}

export default Modal3