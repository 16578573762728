import React from "react"

import Header from "./Header"
import Sidebar from "./Sidebar"

const Whitepaper = () => {
  const userInfo = localStorage.getItem("greennetwork")

return (
    <main>
      <Header>{userInfo ? localStorage.getItem("airdrop"):0}</Header><Sidebar/>

      <div className="main-section unselectable">
          <div className="whitepaper">
          <div className="whitepaper-protect"></div>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0001.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0002.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0003.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0004.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0005.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0006.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0007.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0008.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0009.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0010.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0011.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0012.png")} alt=""/>
          <img className="whitepaper-image" src={require("./images/WhitePaper/0013.png")} alt=""/>

        </div>
       
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
        </svg>
      </div>
    </main>

)
}

export default Whitepaper
