import React, { useState, useEffect } from "react"

import axios from "axios"
import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"
import ModalShare from "./Modal/modalshare"

import { PiSigmaThin } from "react-icons/pi"

const Referral = () => {
  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState(''); const [message2, setMessage2] = useState(''); const [message3, setMessage3] = useState('')
  const [message4, setMessage4] = useState(''); const [share, setShare] = useState('')

  const [user, setUser] = useState()
  const [index, setIndex] = useState(0)
  const [data, setData] = useState()
  const Data0 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0]
  const [rows, setRow] = useState([])
  const [row0, setRow0] = useState([])

  const userInfo = localStorage.getItem("greennetwork")
  const config = {headers: {"Content-Type": "application/json", Authorization: `Bearer ${userInfo && userInfo}`}}
  useEffect(() => {if (userInfo){getReferral()}}, [])// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {if (!userInfo){window.location.href="/login"}})// eslint-disable-line react-hooks/exhaustive-deps

  const getReferral = async (user) => {
    setLoader(true)
    try{
        const { data } = await axios.post(`/api/users/getreferral`, user, config)
        if(data.alert){setMessage2(data.alert)} 
        else {
          setUser(data.user)
          localStorage.setItem("airdrop" , data.user.airdrop)
          setData(data.team)
          const allteam = data.team
          const display = allteam.slice(index, index + 10)
          setRow(display)
          const Row0 = Data0
          if(display.length < 10){Row0.splice(10 - display.length); setRow0(Row0)}else{setRow0([])}
        }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }

  const preTab = async () => { 
    if(index>0){
      const allteam = data
      const newIndex = index - 10
      const display = allteam.slice(newIndex, newIndex + 10)
      setRow(display)
      setIndex(newIndex)
      const Row0 = Data0
      if(display.length < 10){Row0.splice(10 - display.length); setRow0(Row0)}else{setRow0([])}
    }
  }
  const nextTab = async () => { 
    const page = (index+10)/10+1
    const maxpage = Math.ceil(data.length/10)
    if(page <= maxpage) {
      const allteam = data
      const newIndex = index + 10
      const display = allteam.slice(newIndex, newIndex + 10)
      setRow(display)
      setIndex(newIndex)
      const Row0 = Data0
      if(display.length < 10){Row0.splice(10 - display.length); setRow0(Row0)}else{setRow0([])}
    } else if (data.length < user.totalref) {
      setMessage2("Unable to load all data")
    }
    
  }

  const logOut = async(childData) => {if(childData){window.location.href="/login"}}
return (
    <main>
      <Header logOut={logOut}/><Sidebar logOut={logOut}/>
      {loader && <Loader/>}
      {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
      {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
      {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
      {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}
      {share && <ModalShare closeModal={async(close) => { if(close){setShare("")}}} message={share}/>}

      <div className="main-section unselectable">
        {user && <div className="airdrop">
          
          <div className="body">
          
            <div className="monthyear">
              <div className="month">REFERRAL</div>
              <button className="balance">Referral <i><PiSigmaThin/></i>{user && user.totalref}</button>
              <span className="btn-prev" onClick={()=>preTab()}></span><span className="btn-next" onClick={()=>nextTab()}></span>
            </div>

            <div className="date">
              <div className="main-referral">
                {rows && rows.map((referral) => (
                  <div className="referral">
                    <div className="refcode">{referral.refcode}<span>{referral.verify?"Verified":"Unverified"}</span></div>
                    <div className="earn">{Math.round(referral.dailyreward*0.05)}<span>Earned</span></div>
                  </div>
                ))}

                {row0 && row0.reverse().map((i) => (
                  <div className="blank unselectable">
                    <div className="refcode">REF-CODE-X<span>Unverified</span></div>
                    <div className="earn">0<span>Earned</span></div>
                  </div>
                ))}
              </div>
            </div>

            <div className="footer">
              <p className="balance2">Earned <i><PiSigmaThin/></i>{user && user.refreward} GRC</p>
              <div class="btn__container">
                  <span class="btn-f" onClick={()=>setShare(`${window.location.origin}/@${data && (user.refcode).toLowerCase()}refcode:${user && user.refcode}`)}>
                    <img src={require("./images/qrcore.png")} alt=""/> Invite
                  </span>
              </div>
            </div>

          </div>
        </div>}

        <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
        </svg>
      </div>
    </main>

)
}


export default Referral
