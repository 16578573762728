import React, { useState, useEffect, useRef } from "react"
import copy from "copy-to-clipboard"
import Web3 from "web3"

import Network from "../abis/Network.json"
import TokenABI from "../abis/TokenABI.json"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"
import { PiCopyBold } from "react-icons/pi"
import { IoMdCloseCircle } from "react-icons/io"
import { MdFindInPage } from "react-icons/md"

const GRC = () => {
  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState(''); const [message2, setMessage2] = useState('')
  const [message3, setMessage3] = useState(''); const [message4, setMessage4] = useState('')
  const logOut = async(childData) => {if(childData){}}

  const trustwallet_deeplink = "https://link.trustwallet.com/open_url?coin_id=20000714&url=https://grcoiner.io/contract"
  
  const [selectWallet, setSelectWallet] = useState(false)
  const [deeplinkQR, setDeeplinkQR] = useState(false)

  const addToken = async () => { 
    if(window.ethereum){
      window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3
      //Check Network
      if(await web3.eth.net.getId() !== Network.chainId) {
      try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
      }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
      }//Check Network
      
      const tokenContract = new web3.eth.Contract(TokenABI.abi, Network.Token)
      const symbol = await tokenContract.methods.symbol().call()
      const decimals = await tokenContract.methods.decimals().call()
      await window.ethereum.request({method: 'wallet_watchAsset',params: {
          type: 'ERC20', options: {address: Network.Token, symbol, decimals, image: Network.Image}
      }})
    } else {setSelectWallet(true)}
  }

  const [copymessage, setCopyMSG] = useState("")
  function copyText(text) {
    copy(text); setCopyMSG("Copied")
    const interval = setInterval(() => {setCopyMSG("")}, 1000)
    return () => clearTimeout(interval)
  }

return (<>
  <main>
    <Header logOut={logOut}/><Sidebar logOut={logOut}/>
    {loader && <Loader/>}
    {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
    {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
    {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
    {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}
    <div className="main-section">

      <div className="normal-page">
        <h3>GRC Smart Contract</h3>
        <p>Address: <input value={Network.Token}/>
          <i hidden={copymessage} style={{top:"-5px"}} onClick={()=>{copyText(Network.Token)}}><PiCopyBold/></i>
          <i style={{top:"-5px", marginLeft:"25px"}}><a href="https://bscscan.com/token/0x2eD69BE6649E73105fbd55466164bB4Da77EDB4F" target="_blank"><MdFindInPage/></a></i>
          {copymessage && <div className="copy-message">{copymessage}</div>}
        </p>
        <p>Network: {Network.chainName} <img style={{width:"20px"}} src={require("./images/bnb-icon.png")} alt=""/></p>
        <p>Total supply: 2,100,000,000</p>

        <h3 style={{marginTop:"50px"}}>Add GRC to wallet</h3>
        <p style={{color:"rgb(247, 247, 175)", fontWeight:"600", marginTop:"25px"}}>Add GRC to wallet automatically</p>
        <div style={{position:"relative"}}>
          <p className="import-btn" onClick={()=>addToken()}><button>IMPORT</button></p>

          {selectWallet && <div className="selectWallet">
            <a><img onClick={()=>{setSelectWallet(false); setDeeplinkQR(true)}} src={require("./images/metamask.png")} alt=""/></a>
            <a href={trustwallet_deeplink} target="_blank"><img onClick={()=>{setSelectWallet(false)}} src={require("./images/trustwallet.png")} alt=""/></a>
            <div className="close" onClick={()=>{setSelectWallet(false)}}><IoMdCloseCircle/></div>
          </div>}
          {deeplinkQR && <div className="selectWallet-QR">
            <span>Open Metamask & Scan QR code</span>
            <div className="qrcode">
              <img src={require("./images/metamask-deeplink-contract.png")} alt=""/>
            </div>
            <div className="close" onClick={()=>{setDeeplinkQR(false)}}><IoMdCloseCircle/></div>
          </div>}
        </div>
        
        <p style={{color:"rgb(247, 247, 175)", fontWeight:"600", marginTop:"20px"}}>Add GRC to wallet manually</p>
        <p>1. Select Network</p>
        <p>If you are using Trust wallet, BNB Smart Chain is already activated. If you use wallets that do not have BNB Smart Chain enabled, you must add that network to your wallet.</p>
        <p>Add the following information</p>
        <div className="details">
          <p>Chain name: {Network.chainName}</p>
          <p>Symbol: {Network.symbol}</p>
          <p>Chain ID: {Network.chainId}</p>
          <p>RpcURL: {Network.rpcUrls}</p>
          <p>BlockExplorerURL: {Network.blockExplorerUrls}</p>
        </div>

        <p>2. Add GRC Token</p>
        <p>After selecting the network, you can add tokens to your wallet. Find and tap on <span style={{color:"yellow"}}>Import token</span> button in your wallet</p>
        <p>Add the following information</p>
        <div className="details">
          <p>Contract: <input value={Network.Token}/>
            <i hidden={copymessage} onClick={()=>{copyText(Network.Token)}}><PiCopyBold/></i>
            {copymessage && <div className="copy-message">{copymessage}</div>}
          </p>
          <p>Token name: GRC TOKEN</p>
          <p>Symbol: GRC</p>
          <p>Decimal: 18</p>
        </div>
      </div>

      <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
      </svg>
    </div>
  </main>
</>)
}

export default GRC
