import React from "react"
import { IoMdCloseCircle } from "react-icons/io"

const saleModal = (props) => {
    const closeModal = async () => {props.closeModal(true)}

  return (
    <div className="clockmain unselectable"><div className="clock-window">

      <div className="signboard outer">
          <div className="close" onClick={()=>{localStorage.setItem("saleNotice", true); closeModal()}}><IoMdCloseCircle/></div>
          <div className="link" onClick={()=>{localStorage.setItem("saleNotice", true); window.location.href="/tokensale"}}>LET'S GO</div>

          <div className="signboard top">
              <li className="title">
                  <span>BUY GRC</span>
              </li>
          </div>
      </div>

    </div></div>
  )
}

export default saleModal