import React from "react"
import axios from "axios"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"
import Selfie from "./Modal/selfie"
import KYCMessage from "./Modal/kycmessage"

import { PiCameraRotateFill } from "react-icons/pi"


class KYC extends React.Component {
  constructor() {
    super()
    this.cameraNumber = 0
    this.state = {
      userData: [], serverData: [], isSubmit: false, isVerified: false, isOver: false, kycmessage: null,
      openStream: false, numberPhoto: 0, switchAble: false, cameraloaded: false,
      image01Data: null, image02Data: null, image03Data: null, canvasWidth: 0, canvasHeight: 0,
      loader: false, message1:"", message2:"", message3:"", message4:"", 
      selfieTips: false, step: 1, idtype: "ID Card", idnumber: "", firtOpen: true, process: "",
      frame: "frame0", videosize: ""
    }
  }

  componentDidMount() {
    if(this.userInfo){this.getUser()}
  } 

  userInfo = localStorage.getItem("greennetwork")
  config = {headers: {"Content-Type": "application/json", Authorization: `Bearer ${this.userInfo && this.userInfo}`}}

  async getUser(user) {
    this.setState({loader:true})
    try{
        const { data } = await axios.post(`/api/users/getuser`, user, this.config)
        if(data.alert){this.setState({message2:data.alert})} 
        else {
          this.setState({userData: data.user, serverData: data.server})

          if(!data.user.verify){this.checkCamera()}

          if(data.user.verify){this.setState({isVerified:true})}
          if(data.user.pic1[0] && data.user.pic1[0].url){this.setState({isSubmit:true})}
          if(data.user.countkyc===3){this.setState({isOver:true})}
          if(data.user.kycmessage && data.user.kycmessage[0]){this.setState({kycmessage: data.user.kycmessage})}
          if(data.user.idnumber){this.setState({idnumber: data.user.idnumber})}
        }
    }catch(error){this.setState({message4:error})}
    this.setState({loader:false})
  }

  async removemessage(user) {
    this.setState({kycmessage:null})
    await axios.post(`/api/users/removemessage`, user, this.config)
  }

  async saveIDNumber(user) {
    this.setState({loader:true})
    try{
      const idnumber = this.state.idnumber
      const { data } = await axios.post(`/api/users/saveidnumber`, {user, idnumber}, this.config)
      if(data.alert){this.setState({message2:data.alert})} 
      else {this.setState({step: 2})}
    }catch(error){this.setState({message4:error})}
    this.setState({loader:false})
  }

  async initializeMedia(number) {
    if (!("mediaDevices" in navigator)) {navigator.mediaDevices = {}}
    if (!("getUserMedia" in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        if (!getUserMedia) {return Promise.reject(new Error("getUserMedia Not Implemented"))}
        return new Promise((resolve, reject) => {getUserMedia.call(navigator, constraints, resolve, reject)})
      }
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoInputs = devices.filter(device => device.kind === 'videoinput')
    //const videoInputs = await this.getListOfVideoInputs()

    if (videoInputs.length) {
      this.setState({openStream:true, cameraloaded:false})
      if(this.state.firtOpen){
        if(number===1){this.cameraNumber = videoInputs.length-1}
        if(number===2){this.cameraNumber = videoInputs.length-1}
        if(number===3){this.cameraNumber = 0}
      }

      navigator.mediaDevices
        .getUserMedia({video: {
          deviceId: {exact: videoInputs[this.cameraNumber].deviceId},
        }})
        .then((stream) => {
          this.player.srcObject = stream
          this.player.play()
          if(number===1){this.setState({numberPhoto:1})}
          if(number===2){this.setState({numberPhoto:2})}
          if(number===3){this.setState({numberPhoto:3})}
          if(videoInputs.length>1){this.setState({switchAble: true})}
          this.setState({firtOpen:false})
          this.setState({cameraloaded:true})
        })
        .catch((error) => {console.error(error)})
    } else {this.setState({message2:"The device does not have a camera"})}
  }

  getVideoSize = async () => {
    this.setState({cameraloaded: false})
    if(this.player.videoWidth >= this.player.videoHeight) {
      this.setState({frame: "frame1"})
    }else{this.setState({frame: "frame2"})}
  }

  async checkCamera() {
    if (!("mediaDevices" in navigator)) {navigator.mediaDevices = {}}
    if (!("getUserMedia" in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        if (!getUserMedia) {return Promise.reject(new Error("getUserMedia Not Implemented"))}
        return new Promise((resolve, reject) => {getUserMedia.call(navigator, constraints, resolve, reject)})
      }
    }
    const devices = await navigator.mediaDevices.enumerateDevices()
    const videoInputs = devices.filter(device => device.kind === 'videoinput')
    if (videoInputs.length) {
      navigator.mediaDevices
      .getUserMedia({video: true})
      .then((stream) => {
        var track = stream.getTracks()[0]
        track.stop()
      })
      .catch((error) => {console.error(error)})
    } else {console.log("The device does not have a camera")}
  }


  async capturePicture(number) {
    var canvas = document.createElement("canvas")
    var contex = canvas.getContext("2d")

    if(this.player.videoWidth >= this.player.videoHeight) {
      canvas.width = this.player.videoWidth
      canvas.height = this.player.videoHeight
      contex.drawImage(this.player, 0, 0, canvas.width, canvas.height)
    } else {
      canvas.width = this.player.videoHeight
      canvas.height = this.player.videoWidth
      contex.translate(canvas.width/2, canvas.height/2)
      if(this.cameraNumber===0){contex.rotate(90*Math.PI/180)}
      else{contex.rotate(-90*Math.PI/180)}
      contex.drawImage(this.player, -(this.player.videoWidth/2), -(this.player.videoHeight/2))
    }

    this.player.srcObject.getVideoTracks().forEach((track) => {track.stop()})

    if(number===1){this.setState({image01Data: canvas, openStream: false})}
    if(number===2){this.setState({image02Data: canvas, openStream: false})}
    if(number===3){this.setState({image03Data: canvas, openStream: false})}
  }
  async switchCamera(number) {
    const listOfVideoInputs = await this.getListOfVideoInputs()
    if (listOfVideoInputs.length > 1) {
      if (this.player.srcObject) {this.player.srcObject.getVideoTracks().forEach((track) => {track.stop()})}
      if (this.cameraNumber === 0) {this.cameraNumber = listOfVideoInputs.length-1}
      else if (this.cameraNumber === listOfVideoInputs.length - 1) {this.cameraNumber = 0}
      this.initializeMedia(number)
    } else if (listOfVideoInputs.length === 1) {
      this.setState({message2:"The device has only one camera"})
    } else {
      this.setState({message2:"The device does not have a camera"})
    }
  }

  getListOfVideoInputs = async () => {
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices()
    return enumerateDevices.filter((device) => device.kind === "videoinput")
  }

  userInfo = localStorage.getItem("greennetwork")
  config = {headers: {"Content-Type": "application/json", Authorization: `Bearer ${this.userInfo && this.userInfo}`}}
  logOut = async(childData) => {if(childData){window.location.href="/login"}}

  async checkKYC(user) {
    this.setState({loader:true})
    this.setState({process:"Checking..."})
    try{
        const { data } = await axios.post(`/api/users/checkkyc`, user, this.config)
        if(data.alert){this.setState({message2:data.alert})} 
        else {
          this.submitKYC()
        }
    }catch(error){
      this.setState({message4:error})
      this.setState({loader:false})
      this.setState({process:""})
    }
  }

  async submitKYC(user) {
    this.setState({loader:true})

    const preset = this.state.serverData.preset
    const cloudname = this.state.serverData.cloudname
    try{
      const data1 = new FormData(); data1.append("file", this.state.image01Data.toDataURL()); data1.append("upload_preset", preset); data1.append("cloud_name", cloudname)
      const data2 = new FormData(); data2.append("file", this.state.image02Data.toDataURL()); data2.append("upload_preset", preset); data2.append("cloud_name", cloudname)
      const data3 = new FormData(); data3.append("file", this.state.image03Data.toDataURL()); data3.append("upload_preset", preset); data3.append("cloud_name", cloudname)

      this.setState({process:"Image 1: Uploading..."})
      const pic1 = await fetch(`https://api.cloudinary.com/v1_1/${cloudname}/image/upload`, {method: "post", body: data1})
      .then((res) => res.json()) .then((data) => {return data}).catch((err) => {return []})

      this.setState({process:"Image 2: Uploading..."})
      const pic2 =  await fetch(`https://api.cloudinary.com/v1_1/${cloudname}/image/upload`, {method: "post", body: data2})
      .then((res) => res.json()) .then((data) => {return data}).catch((err) => {return []})

      this.setState({process:"Image 3: Uploading..."})
      const pic3 = await fetch(`https://api.cloudinary.com/v1_1/${cloudname}/image/upload`, {method: "post", body: data3})
      .then((res) => res.json()) .then((data) => {return data}).catch((err) => {return []})

      if(pic1 && pic2 && pic3) {
        try{
          this.setState({process:"Almost done..."})
          const idnumber = this.state.idnumber
          const { data } = await axios.post(`/api/users/submitkyc`, {user, pic1, pic2, pic3, idnumber}, this.config)
          if(data.alert){this.setState({message2:data.alert})} 
          else {
            this.setState({userData: data.user, serverData: data.server})
            this.setState({isSubmit:true})
            this.setState({message1:"KYC verification request sent"})
            this.setState({step:1})
          }
        }catch(error){this.setState({message4:error})}

      }else{this.setState({message2:"Submitting KYC verification request failed. Please try again."})}

    } catch(error){console.log(error)}
    this.setState({loader:false})
    this.setState({process:""})
  }
  render() {
    return (
      <main>
        <Header logOut={this.logOut}/><Sidebar logOut={this.logOut}/>
        {this.state.loader && <Loader/>}
        {this.state.message1 && <Modal1 closeModal={async(close) => { if(close){this.setState({message1:""})}}} message={this.state.message1}/>}
        {this.state.message2 && <Modal2 closeModal={async(close) => { if(close){this.setState({message2:""})}}} message={this.state.message2}/>}
        {this.state.message3 && <Modal3 closeModal={async(close) => { if(close){this.setState({message3:""})}}} message={this.state.message3}/>}
        {this.state.message4 && <Modal4 closeModal={async(close) => { if(close){this.setState({message4:""})}}} message={this.state.message4}/>}
        {this.state.selfieTips && <Selfie closeModal={async(close) => { if(close){this.setState({selfieTips: false})}}}/>}

        {this.state.kycmessage  && <KYCMessage action={async(action) => {
          if(action.close){this.setState({kycmessage:null})}
          if(action.dontshow){this.removemessage()}
        }} message={this.state.kycmessage}/>}

        <div className="main-section">
        {this.userInfo && 
          <div className="loginform kycpage">

            {this.state.step===1 && <div style={{marginBottom:"15px"}}>
              <h3>KYC VERIFICATION</h3>
              <div className="kyc-selfie">
                {this.state.userData && this.state.userData.verify ?
                  <img width="100%" src={require("./images/selfie-verified.png")} alt=""/>:
                  <img width="100%" src={require("./images/selfie.png")} alt=""/>
                }
                {this.state.userData.pic3 && this.state.userData.pic3[0] ? <></>:<><div className="eye left"></div><div className="eye right"></div></>}
              </div>
            </div>}

            {this.state.step===1 && <div style={{textAlign:"left"}}>

              {
              this.state.isVerified ? 
              <>
                <p style={{color:"yellow", textAlign:"center"}}>Your identity has been verified. Congratulations!</p>
              </>:
              this.state.isSubmit ?
              <>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-person-vcard" viewBox="0 0 16 16">
                  <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z"/>
                </svg> ID Type: National ID Card <br/>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-person-vcard" viewBox="0 0 16 16">
                  <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z"/>
                </svg> ID Number: {this.state.userData.idnumber} <br/>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-person-vcard" viewBox="0 0 16 16">
                  <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z"/>
                </svg> Status: in Process <br/>
                <p style={{color:"yellow", textAlign:"center"}}>The reviewal is estimated to be done within one week, please wait patiently</p>
              </>:
              this.state.isOver ?
              <p style={{color:"#F87171", textAlign:"center"}}>You have failed 3 times to verify your identity</p>
              :
              <>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-person-vcard" viewBox="0 0 16 16">
                  <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z"/>
                </svg> <b>ID Type</b>
                <div className="select">
                  <select value={this.state.idtype} onChange={(e)=>this.setState({idtype: e.target.value})}>
                    <option value="ID Card">National ID Card</option>
                  </select>
                </div>

                <input style={{marginTop:"5px"}} placeholder={this.state.idtype+" number"} 
                value={this.state.idnumber} onChange={(e)=>this.setState({idnumber: e.target.value.split(' ').join('').trim().replace(/[^a-zA-Z\d]/ig, "").toUpperCase()})} />

                <div style={{textAlign:"center", marginTop:"20px"}}>
                  <button className="submit-btn" disabled>BACK</button>
                  <button className="submit-btn" disabled={!this.state.idnumber && true} onClick={()=>this.saveIDNumber()}>NEXT</button>
                </div>
              </>
              }
            </div>}

            {this.state.step===2 &&<div className="photo">
              <h3>Front side of <b style={{color:"#FCD34D"}}>{this.state.idtype}</b></h3>
              {this.state.image01Data ?
                <div className="blankImage">
                  <img src={this.state.image01Data.toDataURL()} alt=""/>
                  <button className="deleteImage-btn" onClick={()=>this.setState({image01Data:null})}><i className="bi bi-x-circle-fill"></i></button>
                </div>
                :
                <div className="blankImage" title="Camera">
                  <button className="openCamera-btn" onClick={()=>{this.initializeMedia(1); this.setState({firtOpen:true})}}><i className="bi bi-camera-fill"></i></button>
                </div>
              }
              <div style={{textAlign:"center", marginTop:"20px"}}>
                <button className="submit-btn" onClick={()=>this.setState({step: this.state.step-1})}>BACK</button>
                <button className="submit-btn" disabled={!this.state.image01Data && true} onClick={()=>this.setState({step: this.state.step+1})}>NEXT</button>
              </div>
            </div>}

            {this.state.step===3 && <div className="photo">
              <h3>Back side of <b style={{color:"#FCD34D"}}>{this.state.idtype}</b></h3>
              {this.state.image02Data ?
                <div className="blankImage">
                    <img src={this.state.image02Data.toDataURL()} alt=""/>
                    <button className="deleteImage-btn" onClick={()=>this.setState({image02Data:null})}><i className="bi bi-x-circle-fill"></i></button>
                  </div>
                :
                <div className="blankImage" title="Camera">
                  <button className="openCamera-btn" onClick={()=>{this.initializeMedia(2); this.setState({firtOpen:true})}}><i className="bi bi-camera-fill"></i></button>
                </div>
              }
              <div style={{textAlign:"center", marginTop:"20px"}}>
                <button className="submit-btn" onClick={()=>this.setState({step: this.state.step-1})}>BACK</button>
                <button className="submit-btn" disabled={!this.state.image02Data && true} onClick={()=>this.setState({step: this.state.step+1})}>NEXT</button>
              </div>
            </div>}

            {this.state.step===4 && <div className="photo">
              <h3>Selfie: hold <b style={{color:"#FCD34D"}}>{this.state.idtype}</b> & Note</h3>
              <p style={{lineHeight:"5px", textAlign:"left", color:"#FCD34D", cursor:"pointer"}} onClick={()=>this.setState({selfieTips: true})}>Tap on here to see how</p>
              {this.state.image03Data ?
                <div className="blankImage">
                    <img src={this.state.image03Data.toDataURL()} alt=""/>
                    <button className="deleteImage-btn" onClick={()=>this.setState({image03Data:null})}><i className="bi bi-x-circle-fill"></i></button>
                  </div>
                :
                <div className="blankImage" title="Camera">
                  <button className="openCamera-btn" onClick={()=>{this.initializeMedia(3); this.setState({firtOpen:true})}}><i className="bi bi-camera-fill"></i></button>
                </div>
              }
              <div style={{textAlign:"center", marginTop:"20px"}}>
                <button className="submit-btn" onClick={()=>this.setState({step: this.state.step-1})}>BACK</button>
                <button className="submit-btn" disabled={!this.state.image03Data && true} onClick={()=>this.setState({step: this.state.step+1})}>NEXT</button>
              </div>
            </div>}

            {this.state.step===5 && <div className="photo">
              <h3>KYC VERIFICATION</h3>

              <p style={{marginTop:"20px", textAlign:"left"}}>Please make sure that you use the correct ID type, fill in the correct ID document number and that all images meet our conditions</p>
              <p style={{textAlign:"left"}}>Other types of ID documents not listed will not be accepted</p>
              <p style={{textAlign:"left"}}>You currently have {3-this.state.userData.countkyc} opportunities to apply</p>
        
              <div style={{textAlign:"center", marginTop:"20px"}}>
                {
                  this.state.process ?
                  <p style={{color:"#FCD34D"}}>{this.state.process}</p>:<>
                    <button className="submit-btn" onClick={()=>this.setState({step: this.state.step-1})}>BACK</button>
                    <button className="submit-btn" onClick={()=>this.checkKYC()}>SUBMIT</button>
                  </>
                }
              </div>
            </div>}

            <div>


            {/* CAMERA */}
            {this.state.openStream && <div className="Stream-bg"><div className="Stream">
              <div className="Video">
                <video className={this.cameraNumber===0 ?"flip":""} style={{width:"100%"}} ref={(refrence) => {this.player = refrence}} autoPlay></video>

                {this.state.numberPhoto===1 && <i className={`capture-btn bi bi-camera-fill ${this.state.frame==="frame2"?"rotate":""}`} onClick={()=>this.capturePicture(1)}></i>}
                {this.state.numberPhoto===2 && <i className="capture-btn bi bi-camera-fill" onClick={()=>this.capturePicture(2)}></i>}
                {this.state.numberPhoto===3 && <i className="capture-btn bi bi-camera-fill" onClick={()=>this.capturePicture(3)}></i>}

                {this.state.switchAble && <>
                {this.state.numberPhoto===1 && <PiCameraRotateFill className="switchCamera-btn" onClick={()=>this.switchCamera(1)}/>}
                {this.state.numberPhoto===2 && <PiCameraRotateFill className="switchCamera-btn" onClick={()=>this.switchCamera(2)}/>}
                {this.state.numberPhoto===3 && <PiCameraRotateFill className="switchCamera-btn" onClick={()=>this.switchCamera(3)}/>}
                </>}

                <div className={`arrow ${this.state.frame}`}><img src={require("./images/arrow.png")} alt=""/></div>

              </div>
              
              {this.state.cameraloaded && this.state.numberPhoto===1 &&
                <div className="camera-instruction">
                  <h3>INSTRUCTIONS</h3>
                  <p>Get all 4 corners in the frame and place the ID in the correct direction</p>
                  <p>Make sure you capture a clear and complete image. The details on the ID in the image must be readable. They cannot be blurry or out of focus</p>
                  <img src={require("./images/tips.png")} alt=""/>
                  <button onClick={()=>this.getVideoSize()}>OKE</button>
                </div>
              }
            </div></div>
            }{/* CAMERA */}   

            </div>

 

         </div>
        }
        </div>
        
        <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
        </svg>
      </main>
    );
  }
}

export default KYC