import React, { useState, useEffect } from "react"
import { IoMdCloseCircle } from "react-icons/io"

const Telegram = (props) => {
  const closeModal = async () => {props.closeModal(true)}

  const [link, setLink] = useState("")
  const [account, setAccount] = useState("")

  useEffect(() => {
    if(props.message){
      setLink(props.message)
      const array = (props.message).split("t.me/")
      setAccount("@"+array[1])
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="telegram unselectable">
        <div id="transparent-box">
          <div className="close" onClick={()=>closeModal()}><IoMdCloseCircle/></div>

          <div className="biglogo"><img src={require("../images/telegram.png")} alt=""/></div>

          <div className="small">Join Us On</div>

          <div className="big">TELEGRAM</div>

          <div className="button">
            <img src={require("../images/telegram2.png")} alt=""/>
            <a  onClick={()=>localStorage.setItem("telegramNotice", true)} href={link} target="_blank" rel="noopener noreferrer">{account}</a>
          </div>
        </div>

    </div>
  )
}

export default Telegram