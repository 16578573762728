import React, { useState } from "react"
import ImageSVG from "../abis/ImageSVG.json"
import APK from "./images/grcoiner.apk"
import { FiAlertTriangle } from "react-icons/fi"
const Header = (props) => {
  const [userInfo, setUserInfo] = useState(localStorage.getItem("greennetwork"))

    function LogOut() {
      localStorage.removeItem("greennetwork")
      localStorage.removeItem("airdrop")
      setUserInfo(null)
      props.logOut(true)
    }

    return (
      <nav className="navbar navbar-expand-lg">
        <div className="container unselectable">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img src={ImageSVG.logo} className="navbar-brand-image img-fluid" alt=""/>
            <span className="navbar-brand-text">
              <big>GREEN</big>
              <div className="middle"></div>
              <small>Network</small>
            </span>
          </a>

          <div className="d-lg-none ms-auto me-3">
            {
             userInfo ?
              <a className="btn custom-btn" style={{marginRight:"20px"}} role="button" href="/dashboard">GRC {localStorage.getItem("airdrop")}</a>:
              <a className="btn custom-btn" style={{marginRight:"20px"}} role="button" href="/login">Member Login</a>
            }
          </div>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-lg-auto">

              <li className="nav-item"><a className="nav-link" href="/">Home</a></li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Token
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarLightDropdownMenuLink">
                  <li><a className="dropdown-item" href="/contract">Contract</a></li>
                  <li><a className="dropdown-item" href="/stake">Staking</a></li>
                  <li><a className="dropdown-item" href="/">Buyback</a></li>
                </ul>
              </li>
              
              <li className="nav-item"><a className="nav-link" href="/airdrop">Airdrop</a></li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Account
                </a>
                {userInfo ? 
                <ul className="dropdown-menu" aria-labelledby="navbarLightDropdownMenuLink">
                  <li><a className="dropdown-item" href="/dashboard">Dashboard</a></li>
                  <li><a className="dropdown-item" href="/security">Security</a></li>
                  <li><a className="dropdown-item" href="/referral">Referral</a></li>
                  <li><a className="dropdown-item" >KYC</a><FiAlertTriangle style={{position:"absolute", top:"7px", right:"20px"}}/></li>
                  <li><a className="dropdown-item" href="/logs">My Logs</a></li>
                  <li><a className="dropdown-item" href="/delete">Account deletion</a></li>
                  <li><a className="dropdown-item signout" onClick={()=>LogOut()}>Sign Out</a></li>
                </ul>:
                <ul className="dropdown-menu" aria-labelledby="navbarLightDropdownMenuLink">
                  <li><a className="dropdown-item" href="/login">Login</a></li>
                  <li><a className="dropdown-item" href="/register">Register</a></li>
                  <li><a className="dropdown-item" href="/login">Forgot password?</a></li>
                </ul>
                }
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  About
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarLightDropdownMenuLink">
                  <li><a className="dropdown-item" href="/#roadmap">Roadmap</a></li>
                  <li><a className="dropdown-item" href="/whitepaper">Whitepaper</a></li>
                  <li><a className="dropdown-item" href="/document">Document</a></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Download
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarLightDropdownMenuLink">
                  <li><a className="dropdown-item">Google Play</a></li>
                  <li><a className="dropdown-item">App Store</a></li>
                  <li><a className="dropdown-item" download href={APK}>Android APK</a></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src={require("./images/flag.png")} alt=""/>
                </a>
              </li>

            </ul>

            <div className="d-none d-lg-block ms-lg-3">
              {
              userInfo ?
                <a className="btn custom-btn" role="button" href="/dashboard">GRC {localStorage.getItem("airdrop")}</a>:
                <a className="btn custom-btn" role="button" href="/login">Member Login</a>
              }
            </div>

          </div>
        </div>
      </nav>
    )
}


export default Header
