import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import "./screens/css/main.css"
import "./screens/css/bar.css"
import "./screens/css/loginform.scss"
import "./screens/css/animation.scss"
import "./screens/css/airdrop.scss"
import "./screens/css/dashboard.scss"
import "./screens/css/confirm-modal.scss"
import "./screens/css/clock.scss"
import "./screens/css/twitter.scss"
import "./screens/css/telegram.scss"
import "./screens/css/admin.scss"

import "./screens/css/modal1.scss"
import "./screens/css/loader.scss"

import Home from "./screens/Home"
import Login from "./screens/Login"
import Register from "./screens/Register"

//import Airdrop from "./screens/Airdrop"
import Dashboard from "./screens/Dashboard"
import Dashboard2 from "./screens/Dashboard2"
import Security from "./screens/Security"
import Referral from "./screens/Referral"
import Order from "./screens/Order"
import Delete from "./screens/Delete"
import KYC from "./screens/KYC"
import Verify from "./screens/Verify"
import Active from "./screens/Active"

import Whitepaper from "./screens/Whitepaper"

import Admin from "./screens/Admin"
import Blank from "./screens/Blank"

import GRC from "./screens/GRC"
import Presale from "./screens/Presale"
import Stake from "./screens/Stake"
import BuyBack from "./screens/BuyBack"

function App() {
  const userInfo = localStorage.getItem("greennetwork")
  const path = window.location.pathname
  return (
    <Router>
      <Switch>
        <Route path={path}>{
          //path.slice(1,2)==="@" ? <Register/> :
          //path.slice(1,5)==="grc-" ? <Active/> :
          path==="/register" ? <Dashboard2/> :
          path==="/login" ? !userInfo?<Login/>:<Dashboard/> :
          
          path==="/airdrop" ? userInfo?<Dashboard/>:<Login/> :
          path==="/dashboard" ? userInfo?<Dashboard/>:<Login/> :
          path==="/security" ? userInfo?<Security/>:<Login/> :
          path==="/referral" ? userInfo?<Referral/>:<Login/> :
          path==="/logs" ? userInfo?<Order/>:<Login/> :
          path==="/delete" ? userInfo?<Delete/>:<Login/> :
          path==="/kyc" ? userInfo?<KYC/>:<Login/> :

          path==="/admin" ? userInfo?<Admin/>:<Login/> :
          path==="/verify" ? userInfo?<Verify/>:<Login/> :

          path==="/whitepaper" ? <Whitepaper/>:
          path==="/document" ? <Blank/>:
          path==="/contract" ? <GRC/>:
          path==="/stake" ? <Stake/>:
          path==="/buyback" ? <BuyBack/>:

          path==="/"  ?  <Home /> : <Home/>
        }</Route>

      </Switch>
    </Router>
  )
}

export default App
